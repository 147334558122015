import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import useLabels from "../../hooks/useLabels";
import { deleteWinery, getWineries } from "../../services/utils";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { remove, set } from "../../context/wineries/wineriesSlice";
import { selectActiveWinery, selectWineries } from "../../context/selectors";
import { Label } from "../FormComponents";
import SecondaryBar from "../SecondaryBar";
import { Button, ConfirmationModal, CTA, Modal } from "../Generic";

import "./styles/index.scss";
import useAuth from "../../hooks/useAuth";
import { ROLES } from "../../constants/base";

const WineryInfo = ({ winery }) => {
  const [getLabel] = useLabels();

  return (
    <div className="winery_info_container">
      <div className="winery_info_container_row">
        {/* <Label label={getLabel('wineryName')}>{winery.name}</Label> */}
        <Label label={getLabel("wineryAddress")}>{winery.address}</Label>
        <Label label={getLabel("wineryCity")}>{winery.city}</Label>
        <Label label={getLabel("wineryCap")}>{winery.cap}</Label>
        <Label label={getLabel("wineryProvince")}>{winery.province}</Label>
        <Label label={getLabel("wineryCountry")}>{winery.country}</Label>
      </div>
      <div className="winery_info_container_row">
        <Label label={getLabel("wineryMobile")}>{winery.mobile_number}</Label>
        <Label label={getLabel("wineryPhone")}>{winery.phone_number}</Label>
      </div>
      {/* <div className="winery_info_container_row">
            <Label width={400} height={150} label={getLabel('wineryNotes')}>{winery.notes}</Label>
        </div> */}
    </div>
  );
};

const WineryDetail = () => {
  const activeWinery = useSelector(selectActiveWinery);
  const { id } = useParams();
  const wineries = useSelector(selectWineries);
  const [getLabel] = useLabels();
  const dispatch = useDispatch();
  const axiosPrivate = useAxiosPrivate();
  const [winery, setWinery] = useState({});
  const navigate = useNavigate();
  const { auth } = useAuth();
  const [isOpen, setIsOpen] = useState(false);

  const handleOpenModal = () => {
    setIsOpen(true);
  };

  const handleCloseModal = () => {
    setIsOpen(false);
  };

  const handleFormSubmit = async () => {
    await removeWinery();
    handleCloseModal();
  };

  const role = auth?.role || ROLES.GUEST;
  const pageName = getLabel("wineryTitlePage", { name: winery?.name || id });

  useEffect(() => {
    if (!wineries || wineries.length === 0) {
      loadWineries();
    }
  }, []);

  useEffect(() => {
    if (wineries && wineries.length > 0) {
      setWinery(
        wineries.find((dbWinery) => {
          return dbWinery.id === Number(id);
        }) || {}
      );
    }
  }, [wineries]);

  const loadWineries = async () => {
    const currentWineries = await getWineries(activeWinery.id, axiosPrivate);
    dispatch(set(currentWineries));
  };

  const removeWinery = async () => {
    const response = await deleteWinery(id, axiosPrivate);
    const requestPayload = {
      ...(id && { id: Number(id) }),
    };
    if (response && response?.success) {
      dispatch(remove(requestPayload));
      navigate("/wineries");
    }
  };

  const renderNavMenu = () => {
    const navClickHandler = () => {
      navigate("/wineries");
    };
    return (
      <div className="primary_container_menu">
        <div className="primary_container_row">
          <Button arrowDirection="left" onClick={navClickHandler} />
          <h2>{pageName}</h2>
        </div>
        <div className="primary_container_row">
          {role === ROLES.CLIENT && (
            <>
              <NavLink to={`/wineries/${id}/update`}>
                <CTA>{getLabel("updateWinery")}</CTA>
              </NavLink>

              <CTA onClick={handleOpenModal}>{getLabel("deleteWinery")}</CTA>
              <ConfirmationModal
                isOpen={isOpen}
                onConfirm={handleFormSubmit}
                onClose={handleCloseModal}
                description={getLabel('modalWineryDeleteDescription', {name: winery?.name || ''})}
              >
              </ConfirmationModal>
            </>
          )}
        </div>
      </div>
    );
  };

  const renderNextOperation = () => {
    return (
      <div>
        <div className="primary_container_menu">
          <h4>{getLabel("winery_nextOperations")}</h4>
          <NavLink to={"/programming/new"}>
            <CTA>{getLabel("programOperation")}</CTA>
          </NavLink>
        </div>
      </div>
    );
  };

  return (
    <div className="primary_container">
      <SecondaryBar
        breadCrumb={[
          getLabel("wineriesNavLink"),
          getLabel("wineryTitlePage", { name: "" }),
        ]}
        isBasePathNeeded={false}
      />
      {renderNavMenu()}
      <WineryInfo winery={winery} />
      {/* {renderNextOperation()} */}
    </div>
  );
};

export default WineryDetail;
