import { useNavigate, useParams } from "react-router-dom";
import useLabels from "../../hooks/useLabels";
import { Button } from "../Generic";
import SecondaryBar from "../SecondaryBar";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { selectActiveWinery, selectWineries } from "../../context/selectors";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useEffect, useState } from "react";
import { getWineries, updateWinery, newWinery } from "../../services/utils";
import { setWinery, add, update } from "../../context/wineries/wineriesSlice";
import { Input, Submit } from "../FormComponents";
import {
  ADDRESS_REGEX,
  ALPHA_NUM_SPACE_DASH_UNDERSCORE_REGEX,
  ALPHA_SPACE_REGEX,
  CAP_REGEX,
  MOBILE_PHONE_REGEX,
  PHONE_REGEX,
  PROVINCE_REGEX,
} from "../../constants/regex";
import { toast } from "react-toastify";
import "./styles/index.scss";

const WineryForm = ({}) => {
  const activeWinery = useSelector(selectActiveWinery);
  const { id } = useParams();
  const [getLabel] = useLabels();
  const navigate = useNavigate();
  const wineries = useSelector(selectWineries);
  const axiosPrivate = useAxiosPrivate();
  const dispatch = useDispatch();
  const [winery, setWinery] = useState(
    wineries?.length > 0
      ? wineries.find((dbWinery) => dbWinery.id === Number(id)) || {}
      : {}
  );

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    reset,
    setValue,
  } = useForm({
    defaultValues: {
      name: winery?.name || "",
      address: winery?.address || "",
      cap: winery?.cap || "",
      province: winery?.province || "",
      city: winery?.city || "",
      country: winery?.country || "",
      phone_number: winery?.phone_number || "",
      mobile_number: winery?.mobile_number || "",
    },
    mode: "onTouched",
  });

  useEffect(() => {
    if (id && (!wineries || wineries.length === 0)) {
      loadWineries();
    }
  }, []);

  useEffect(() => {
    if (id && wineries?.length > 0) {
      const singleWinery =
        wineries.find((dbWinery) => dbWinery.id === Number(id)) || null;
      if (singleWinery) {
        setWinery(singleWinery);
        setValue("name", singleWinery?.name || "");
        setValue("address", singleWinery?.address || "");
        setValue("city", singleWinery?.city || "");
        setValue("cap", singleWinery?.cap || "");
        setValue("province", singleWinery?.province || "");
        setValue("country", singleWinery?.country || "");
        setValue("mobile_number", singleWinery?.mobile_number || "");
        setValue("phone_number", singleWinery?.phone_number || "");
      }
    }
  }, [wineries]);

  const loadWineries = async () => {
    const currentWineries = await getWineries(activeWinery.id, axiosPrivate);
    dispatch(setWinery(currentWineries));
  };

  const submitForm = async (data) => {
    const requestPayload = {
      ...data,
      ...(id && { id: Number(id) }),
    };
    
    const toastId = toast.loading(getLabel("toast_inProgress"), { 
        type: toast.TYPE.INFO,
        position: toast.POSITION.BOTTOM_RIGHT,
        exclude: true,
    })
    let response = null;
    
    if (id) {
        response = await updateWinery(id, requestPayload, axiosPrivate);
    } else {
        response = await newWinery(requestPayload, axiosPrivate)
    }

    toast.update(toastId, { 
        render: response?.success
          ? getLabel(response?.success, { name: data?.name || '' })
          : getLabel(response?.error),
        type: response?.error ? 
            toast.TYPE.ERROR : 
            toast.TYPE.SUCCESS, isLoading: false,
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 4000,
      }
    );
    
    if (response && response?.success) {
      updateStore({...requestPayload, client_name: winery?.client_name});
      navClickHandler();
    }
  };

  const updateStore = (payload) => {
    if (payload.id) {
      dispatch(update(payload));
    } else {
      dispatch(add(payload));
    }
  };

  const navClickHandler = () => {
    if (id) {
      navigate(`/wineries/${id}`);
    } else {
      navigate("/wineries");
    }
  };

  const renderNavMenu = () => {
    const navClickHandler = () => {
      if (id) {
        navigate(`/wineries/${id}`);
      } else {
        navigate("/wineries");
      }
    };
    return (
      <div className="primary_container_menu">
        <div className="primary_container_row">
          <Button arrowDirection="left" onClick={navClickHandler} />
          <h2>{getLabel(id ? "wineriesUpdatePage" : "wineriesNewPage")}</h2>
        </div>
      </div>
    );
  };

  const renderForm = () => {
    return (
      <div className="winery_form_wrapper">
        <form onSubmit={handleSubmit(submitForm)} autoComplete="off" noValidate>
          <div className="winery_form_wrapper_row">
            <Input
              name="name"
              register={register}
              type="text"
              label={getLabel("wineryName")}
              placeholder={getLabel("wineryNamePlaceholder")}
              error={errors["name"]}
              required={getLabel("inputRequiredError")}
              minLength={{
                value: 3,
                message: getLabel("errorMinLength", { value: 3 }),
              }}
              maxLength={{
                value: 30,
                message: getLabel("errorMaxLength", { value: 30 }),
              }}
              regExpPattern={{
                value: ALPHA_NUM_SPACE_DASH_UNDERSCORE_REGEX,
                message: getLabel("errorAlphaNumSpace_-"),
              }}
            />
          </div>
          <div className="winery_form_wrapper_row">
            <Input
              name="address"
              register={register}
              type="text"
              label={getLabel("wineryAddress")}
              placeholder={getLabel("wineryAddressPlaceholder")}
              error={errors["address"]}
              required={getLabel("inputRequiredError")}
              minLength={{
                value: 5,
                message: getLabel("errorMinLength", { value: 5 }),
              }}
              maxLength={{
                value: 30,
                message: getLabel("errorMaxLength", { value: 30 }),
              }}
              regExpPattern={{
                value: ADDRESS_REGEX,
                message: getLabel("errorAlphaNumSpace_-/"),
              }}
            />
            <Input
              name="cap"
              register={register}
              type="text"
              label={getLabel("wineryCap")}
              placeholder={getLabel("wineryCapPlaceholder")}
              error={errors["cap"]}
              required={getLabel("inputRequiredError")}
              minLength={{
                value: 5,
                message: getLabel("errorExactLength", { value: 5 }),
              }}
              maxLength={{
                value: 5,
                message: getLabel("errorExactLength", { value: 5 }),
              }}
              regExpPattern={{
                value: CAP_REGEX,
                message: getLabel("errorCAP"),
              }}
            />
            <Input
              name="province"
              register={register}
              type="text"
              label={getLabel("wineryProvince")}
              placeholder={getLabel("wineryProvincePlaceholder")}
              error={errors["province"]}
              required={getLabel("inputRequiredError")}
              minLength={{
                value: 2,
                message: getLabel("errorExactLength", { value: 2 }),
              }}
              maxLength={{
                value: 2,
                message: getLabel("errorExactLength", { value: 2 }),
              }}
              regExpPattern={{
                value: PROVINCE_REGEX,
                message: getLabel("errorProvince"),
              }}
            />
            <Input
              name="city"
              register={register}
              type="text"
              label={getLabel("wineryCity")}
              placeholder={getLabel("wineryCityPlaceholder")}
              error={errors["city"]}
              required={getLabel("inputRequiredError")}
              minLength={{
                value: 3,
                message: getLabel("errorMinLength", { value: 3 }),
              }}
              maxLength={{
                value: 30,
                message: getLabel("errorMaxLength", { value: 30 }),
              }}
              regExpPattern={{
                value: ALPHA_SPACE_REGEX,
                message: getLabel("errorProvince"),
              }}
            />
            <Input
              name="country"
              register={register}
              type="text"
              label={getLabel("wineryCountry")}
              placeholder={getLabel("wineryCountryPlaceholder")}
              error={errors["country"]}
              required={getLabel("inputRequiredError")}
              minLength={{
                value: 2,
                message: getLabel("errorExactLength", { value: 2 }),
              }}
              maxLength={{
                value: 2,
                message: getLabel("errorExactLength", { value: 2 }),
              }}
              regExpPattern={{
                value: PROVINCE_REGEX,
                message: getLabel("errorCountry"),
              }}
            />
          </div>
          <div>
            <Input
              name="phone_number"
              register={register}
              type="text"
              label={getLabel("wineryPhoneNumber")}
              placeholder={getLabel("wineryPhoneNumberPlaceholder")}
              error={errors["phone_number"]}
              // required={getLabel('inputRequiredError')}
              minLength={{
                value: 10,
                message: getLabel("errorMinLength", { value: 10 }),
              }}
              maxLength={{
                value: 15,
                message: getLabel("errorMaxLength", { value: 15 }),
              }}
              regExpPattern={{
                value: PHONE_REGEX,
                message: getLabel("errorPhoneNumber"),
              }}
            />
            <Input
              name="mobile_number"
              register={register}
              type="text"
              label={getLabel("wineryMobileNumber")}
              placeholder={getLabel("wineryMobileNumberPlaceholder")}
              error={errors["mobile_number"]}
              // required={getLabel('inputRequiredError')}
              minLength={{
                value: 10,
                message: getLabel("errorMinLength", { value: 10 }),
              }}
              maxLength={{
                value: 15,
                message: getLabel("errorMaxLength", { value: 15 }),
              }}
              regExpPattern={{
                value: MOBILE_PHONE_REGEX,
                message: getLabel("errorMobileNumber"),
              }}
            />
          </div>
          <div className="winery_form_wrapper_row">
            <Submit label={getLabel("submitForm")} />
          </div>
        </form>
      </div>
    );
  };

  return (
    <div className="primary_container">
      <SecondaryBar
        breadCrumb={[
          getLabel("wineriesNavLink"),
          getLabel(id ? "wineriesUpdatePage" : "wineriesNewPage"),
        ]}
        isBasePathNeeded={false}
      />
      {renderNavMenu()}
      {renderForm()}
    </div>
  );
};

export default WineryForm;
