import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import useLabels from "../../hooks/useLabels";
import {
  deleteTank,
  getLots,
  getOperations,
  getSensors,
  getTankQR,
  getTanks,
  getTankSensorData,
} from "../../services/utils";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { setTanks, removeT } from "../../context/tanks/tanksSlice";
import {
  selectActiveWinery,
  selectConfigs,
  selectLots,
  selectSensors,
  selectTanks,
  selectUser,
  selectUsers,
} from "../../context/selectors";
import { Label, Slider } from "../FormComponents";
import SecondaryBar from "../SecondaryBar";
import { Button, ConfirmationModal, CTA, Dropdown, Tooltip } from "../Generic";

import NextOperations from "./NextOperations";
import { setLots } from "../../context/lots/lotsSlice";
import AnalysisGrid from "../Home/AnalysisGrid";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LotInfo } from "../LotDetail";
import { setSensors } from "../../context/sensors/sensorsSlice";
import Sensor from "../Sensor";
import DataPlot from "../DataPlot/DataPlot";
import { format } from "date-fns";
import "./styles/index.scss";
import { setItem } from "../../services/LocalStorage";

const TankInfo = ({ tank, tanks = [], lot }) => {
  const [getLabel] = useLabels();
  const axiosPrivate = useAxiosPrivate();
  const configs = useSelector(selectConfigs);
  const users = useSelector(selectUsers);
  const lotName = lot?.name || "";
  const [lotInfoOpen, setLotInfoOpen] = useState(false);
  const MAX_ENABLE_DATA_IN_PLOT = 2;
  const [plotVariables, setPlotVariables] = useState([]);

  useEffect(() => {
    const getSensorData = async () => {
      if (!tank?.id) return;
      const allSensorData = await getTankSensorData(
        "ALL",
        tank?.id,
        axiosPrivate
      );

      const plotVariablesTmp = [];
      if (Object.keys(allSensorData)?.length > 0) {
        const sensorTypes = new Set();
        tank?.sensors?.reduce((_, item) => sensorTypes.add(item?.value), null);
        const sensorTypesArr = Array.from(sensorTypes);

        sensorTypesArr?.forEach((sensorType) => {
          const sensorData = allSensorData?.collected_values?.filter(
            (ss) => ss?.type === sensorType
          )?.sort((a, b) => (new Date(b.collection_date) - new Date(a.collection_date)));
          
          const currentSensor = tank?.sensors?.find(
            (ss) => ss?.value === sensorType
          );
          const min = currentSensor?.min;
          const max = currentSensor?.max;
          const unit = currentSensor?.unit;
          const color = getColor(sensorType);
          const setPointObj = tank?.sensor_set_points?.find(
            (ss) => ss?.sensor_type === sensorType
          );
          const by_user = users?.find(
            (u) => u?.id === setPointObj?.set_point_by
          )?.username;
          const by_user_time = setPointObj?.set_point_time
            ? format(
                new Date(setPointObj?.set_point_time) || null,
                configs.dateFormat
              )
            : "";
          const setPoint = setPointObj?.set_point || null;
          const lastValue = {
            value: sensorData?.[0]?.value,
            date: new Date(sensorData?.[0]?.collection_date),
          };
          const translatedName = getLabel(
            `sensor_${sensorType?.toUpperCase()}`
          );
          const sensorDataExtended = sensorData?.map((d) => {
            return {
              ...d,
              unit: unit,
              fullDate: format(
                new Date(d?.collection_date) || null,
                configs.dateFormat
              ),
              date: new Date(d?.collection_date).valueOf(),
            };
          });

          const newData = {
            type: sensorType,
            data: sensorDataExtended,
            color: color,
            translated_name: translatedName,
            ...(by_user && { by_user: by_user }),
            ...(by_user_time && { by_user_time: by_user_time }),
            ...(setPoint && { set_point: setPoint }),
            ...(lastValue && { last_point: lastValue }),
            ...(min && { min: min }),
            ...(max && { max: max }),
            ...(unit && { unit: unit }),
            hide: true,
          };
          plotVariablesTmp.push(newData);
        });
      } else if (Object.keys(allSensorData)?.length === 0 && tank?.sensors?.length > 0) {
        // no sensor data available, mock them
        tank?.sensors?.forEach((s) => {
          const translatedName = getLabel(
            `sensor_${s?.value}`
          );
          const newData = {
              "type": s?.value,
              filtered: true,
              hide: true,
              translated_name: translatedName,
          }
          plotVariablesTmp.push(newData);
        })
      }
      
      setPlotVariables(plotVariablesTmp);
      // TO DO else toast
    };

    // call the function
    if (tank) getSensorData().catch(console.error);
  }, [tank]);

  const getColor = (type) => {
    switch (type) {
      case "TEMPERATURE":
        return "#64242E";
      case "HUMIDITY":
        return "#627FE1";
      default:
        return "#64242E";
    }
  };

  const showPlotData = (type, hide) => {
    const foundIndex = plotVariables?.findIndex((v) => v?.type === type);
    if (type) {
      if (foundIndex > -1) {
        const items = [...plotVariables];
        const item = {...items[foundIndex]};
        item.hide = hide;
        items[foundIndex] = item;
        setPlotVariables(items);
      }
    }
  };

  return (
    <div className="tank_info_wrapper">
      <div className="tank_info_container">
        <h4>{getLabel("tankStatus")}</h4>
        <div className="tank_info_container_row">
          {/* <Label label={getLabel("tankName")}>{tank.name}</Label> */}
          <Label
            label={getLabel("tankLot")}
            tooltip={
              lotName?.length > 0 && Number(tank?.quantity) > 0
                ? getLabel("lotInfoOpenDetail")
                : ""
            }
          >
            {lotName?.length > 0 && Number(tank?.quantity) > 0 && (
              <div
                className="lot-info-opener"
                onClick={() => setLotInfoOpen((prev) => !prev)}
              >
                {lotName}
                {/* <p>{getLabel('lotInfoOpenDetail')}</p> */}{" "}
                <FontAwesomeIcon
                  icon="fa-angles-right"
                  className={`lot_arrow ${lotInfoOpen ? "open" : ""}`}
                />
              </div>
            )}
            {(lotName?.length === 0 || Number(tank?.quantity) === 0) &&
              getLabel("emptyTank")}
          </Label>
          <Label
            label={`${getLabel("tankQuantity")}/${getLabel("tankCapacity")}`}
          >
            {tank?.quantity || 0}/{tank.capacity}L (
            {Math.round(((tank?.quantity || 0) / tank?.capacity) * 100)}%)
            <div className="current_tank">
              <Slider
                name={`slider.${tank?.id}.capacity`}
                label={'getLabel("destemmingQuantityPercent")'}
                defaultValue={`${Math.round(
                  (Number(tank?.quantity) * 100) / tank?.capacity
                )}`}
                isLabel={false}
                additional={{ color: tank?.color?.toLowerCase() || "red" }}
              />
            </div>
          </Label>
        </div>
        <h4>{getLabel("tankDetail")}</h4>
        <div className="tank_info_container_row">
          <Label label={getLabel("tankGlobalId")}>{tank?.id_code || "-"}</Label>
          <Label label={getLabel("tankType")}>
            {getLabel(`tankType${tank?.type}`)}
            {["BARRIQUE", "TONNEAU"].includes(tank?.type) &&
              ` (${tank?.barrels_num} x ${
                "BARRIQUE" == tank?.type ? 225 : 500
              }L)`}
          </Label>
          <Label label={getLabel("tankManufacturer")}>
            {tank?.manufacturer || "-"}
          </Label>
          <Label label={getLabel("tankModel")}>{tank?.model || "-"}</Label>
          <Label label={getLabel("tankMaterial")}>{tank.material || "-"}</Label>
          {!["BARRIQUE", "TONNEAU"].includes(tank?.type) && (
            <Label label={getLabel("tankPlant")}>
              {getLabel(`tankPlant${tank.plant?.replace(" ", "-")}`)}
            </Label>
          )}
        </div>
        {!["BARRIQUE", "TONNEAU"].includes(tank?.type) && (
          <div>
            <h4>{getLabel("tankSensors")}</h4>
            <div className="tank_info_container_row">
              {tank?.sensors?.length > 0 && plotVariables?.length > 0 && (
                <div className="sensors_wrapper">
                  {tank?.sensors?.map((s, i) => {
                    return (
                      <Sensor
                        key={`sensor_${i}`}
                        tank_id={tank?.id}
                        data={plotVariables?.find((p) => p?.type === s?.value)}
                        showPlotData={showPlotData}
                      />
                    );
                  })}
                </div>
              )}
              {(tank?.sensors?.length === 0 ||
                (tank?.sensors?.length > 0 && plotVariables?.length === 0)) && (
                <h5 className="alert_no_info">
                  {getLabel("noSensorsForTankFound")}
                </h5>
              )}
            </div>
            <div className="tank_info_container_row">
              <h4></h4>
              {plotVariables?.filter((v) => !v?.hide || v?.hide === false || v?.filtered === true)
                ?.length > 0 && (
                  <DataPlot
                    data={plotVariables?.filter(
                      (v) => !v?.hide || v?.hide === false
                    )}
                  />
                )}
            </div>
          </div>
        )}
        <h5></h5>
        <div className="tank_info_container_row">
          <Label
            width={400}
            height={150}
            label={getLabel("tankNotes")}
            customClassName={"notes"}
          >
            {tank.notes}
          </Label>
        </div>
      </div>
      <div className={`tank_lot_container  ${lotInfoOpen ? "open" : ""}`}>
        <LotInfo lot={lot} tankList={tanks} isDetail={false} />
      </div>
    </div>
  );
};

const TankDetail = () => {
  const activeWinery = useSelector(selectActiveWinery);
  const { id } = useParams();
  const tanks = useSelector(selectTanks);
  const lots = useSelector(selectLots);
  const sensors = useSelector(selectSensors);
  const user = useSelector(selectUser);
  const [getLabel] = useLabels();
  const dispatch = useDispatch();
  const axiosPrivate = useAxiosPrivate();
  const [tank, setTank] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const pageName = getLabel("tankTitlePage", { name: tank?.name || id });
  
  const handleOpenModal = () => {
    setIsOpen(true);
  };

  const handleCloseModal = () => {
    setIsOpen(false);
  };

  const handleFormSubmit = async () => {
    await removeTank();
    handleCloseModal();
  };

  useEffect(() => {
    if (!tanks || tanks.length === 0) {
      loadTanks();
    }
    if (!lots || lots.length === 0) {
      loadLots();
    }
    if (!sensors || sensors.length === 0) {
      loadSensors();
    }
  }, []);

  useEffect(() => {
    if (tanks && tanks.length > 0) {
      setTank(
        tanks.find((dbTank) => {
          return dbTank.id === Number(id);
        }) || {}
      );
    } else if (!tanks || tanks.length === 0) {
      loadTanks();
    }
  }, [tanks]);

  const loadTanks = async () => {
    const currentTanks = await getTanks(activeWinery.id, axiosPrivate);
    dispatch(setTanks(currentTanks));
  };

  const loadLots = async () => {
    const currentLots = await getLots(activeWinery.id, axiosPrivate);
    dispatch(setLots(currentLots));
  };

  const loadSensors = async () => {
    const currentSensors = await getSensors(activeWinery.id, axiosPrivate);
    dispatch(setSensors(currentSensors));
  };

  const removeTank = async () => {
    const toastId = toast.loading(getLabel("toast_inProgress"), {
      type: toast.TYPE.INFO,
      position: toast.POSITION.BOTTOM_RIGHT,
      exclude: true,
    });
    const response = await deleteTank(id, axiosPrivate);
    const requestPayload = {
      ...(id && { id: Number(id) }),
    };

    toast.update(toastId, {
      render: response?.success
        ? getLabel(response?.success, { name: tank?.name || "" })
        : getLabel(response?.error),
      type: response?.error ? toast.TYPE.ERROR : toast.TYPE.SUCCESS,
      isLoading: false,
      position: toast.POSITION.BOTTOM_RIGHT,
      autoClose: 4000,
    });

    if (response && response?.success) {
      dispatch(removeT(requestPayload));
      navigate(`/tanks`);
    }
  };

  const renderLastAnalysis = () => {
    return (
      <>
        <AnalysisGrid id={id} isTank={true} />
        <NavLink to={"/programming/ANALYSIS/new"}>
          <CTA>{getLabel("programOperationANALYSIS")}</CTA>
        </NavLink>
      </>
    );
  };

  const renderIsCurrentMonitorTank = () => {
    return (<div className="primary_container_menu">
        <h4>{getLabel("icCurrentTankMonitor")}</h4>
        <div className="tank_settings">
          <Tooltip
            variant={"info"}
            html={getLabel("setCurrentTankTooltip")}
            place="right"
            events={["hover"]}
          >
            <CTA onClick={setCurrentMonitorTank}>
              {getLabel("setCurrentTank")} <FontAwesomeIcon icon="fa-display" />
            </CTA>
          </Tooltip>
          <CTA onClick={requestQR}>
            {getLabel("downloadTankQR")} <FontAwesomeIcon icon="fa-qrcode" />
          </CTA>
      </div>
    </div>)
  }

  const setCurrentMonitorTank = () => {
    if (id) setItem('currentMonitor', id)
  }

  const requestQR = async () => {
    if (!id) return;
    const qrCode = await getTankQR(id, axiosPrivate);

    if (qrCode) {
      const fileName = `QRCode_${getLabel("tank")}-${tank?.name}`;
      const downloadLink = document.createElement("a");
      downloadLink.href = qrCode;
      downloadLink.download = fileName;
      downloadLink.click();
    }
  };

  const renderNavMenu = () => {
    const navClickHandler = () => {
      navigate("/tanks");
    };
    return (
      <div className="primary_container_menu">
        <div className="primary_container_row">
          <Button arrowDirection="left" onClick={navClickHandler} />
          <h2>{pageName}</h2>
        </div>
        <div className="primary_container_row ctas">
          <NavLink to={`/tanks/${id}/update`}>
            <CTA>
              {getLabel("updateTank")} <FontAwesomeIcon icon="fa-pen" />
            </CTA>
          </NavLink>
          <NavLink to={`/tanks/${id}/tracking`}>
            <CTA>
              {getLabel("showTrackData")} <FontAwesomeIcon icon="fa-timeline" />
            </CTA>
          </NavLink>

          <CTA onClick={handleOpenModal}>
            {getLabel("deleteTank")} <FontAwesomeIcon icon="fa-remove" />
          </CTA>
          <ConfirmationModal
            isOpen={isOpen}
            onConfirm={handleFormSubmit}
            onClose={handleCloseModal}
            description={getLabel("modalDeleteTankDescription", {
              name: tank?.name || "",
            })}
          ></ConfirmationModal>
        </div>
      </div>
    );
  };

  const renderNextOperation = () => {
    return (
      <div>
        <div className="primary_container_menu">
          <h4>{getLabel("nextOperations")}</h4>
          <Dropdown
            label={getLabel("programOperation")}
            options={[
              {
                label: getLabel("operationTypeDESTEMMING"),
                handler: () => navigate("/programming/DESTEMMING/new"),
              },
              {
                label: getLabel("operationTypeCUT"),
                handler: () => navigate("/programming/CUT/new"),
              },
              {
                label: getLabel("operationTypeRACKING"),
                handler: () => navigate("/programming/RACKING/new"),
              },
              {
                label: getLabel("operationTypeTREATMENT"),
                handler: () => navigate("/programming/TREATMENT/new"),
              },
              {
                label: getLabel("operationTypeANALYSIS"),
                handler: () => navigate("/programming/ANALYSIS/new"),
              },
              {
                label: getLabel("operationTypeDECANT"),
                handler: () => navigate("/programming/DECANT/new"),
              },
              {
                label: getLabel("operationTypeWINEMAKING"),
                handler: () => navigate("/programming/WINEMAKING/new"),
              },
              {
                label: getLabel("operationTypeWINEMAKINGPOST"),
                handler: () => navigate("/programming/WINEMAKINGPOST/new"),
              },
              {
                label: getLabel("operationTypeBOTTLING"),
                handler: () => navigate("/programming/BOTTLING/new"),
              },
            ]}
          ></Dropdown>
        </div>
        <NextOperations id={id} type={"TANK"} />
      </div>
    );
  };

  return (
    <div className="primary_container">
      <SecondaryBar
        breadCrumb={[
          getLabel("tanksNavLink"),
          getLabel("tankTitlePage", { name: "" }),
        ]}
      />
      {renderNavMenu()}
      <TankInfo
        tanks={tanks?.filter((t) => t?.batch_name === tank?.batch_name)}
        tank={{
          ...tank,
          sensors: sensors?.filter((s) =>
            tank?.sensors?.enabled?.some((ss) => s?.id === ss?.id)
          ),
        }}
        lot={lots?.find((lot) => tank?.batch_id === lot.id) || {}}
      />
      {renderNextOperation()}
      {renderLastAnalysis()}
      {(user?.type === 'TANK' || true) && renderIsCurrentMonitorTank()}
    </div>
  );
};

export default TankDetail;
