import { useDispatch, useSelector } from "react-redux";
import { selectDrugs, selectExams, selectProtocolDisplayGrid } from "../../context/selectors";
import useLabels from "../../hooks/useLabels";
import { useNavigate } from "react-router-dom";
import ProtocolTile from "./ProtocolTile";
import { Switch } from "../FormComponents";

import "./styles/index.scss";
import { toggleProtocolsDisplayGrid } from "../../context/configs/configsSlice";
import TableEdit from "../TableEdit";
import { createColumnHelper } from "@tanstack/react-table";
import TableCell from "../TableEdit/TableCell";
import MultipleRowsCell from "../TableEdit/MultipleRowsCell";
import { useEffect, useState } from "react";

const ProtocolList = (props) => {
  const { protocolType, protocols, isMobile } = props;
  const displayModeGrid = useSelector(selectProtocolDisplayGrid);
  const dispatch = useDispatch();
  const [getLabel] = useLabels();
  const navigate = useNavigate();
  const substances = useSelector(
    protocolType === "treatment" ? selectDrugs : selectExams
  );
  const [translatedAnalysis, setTranslatedAnalysis] = useState([]);
  
  useEffect(() => {
    if (protocolType === 'analysis') {
      // remove duplicates, keep only unique substance names
      const tmp = [...substances]?.filter((value, index, self) =>
      index === self.findIndex((t) => (
        t?.label === value?.label
      )));

      setTranslatedAnalysis(
        tmp?.map((a) => { return { ...a, translated_name: getLabel(`substance_${a?.name}`) }})
      );
    } else setTranslatedAnalysis([...substances]);
  
  }, [protocols, protocolType])
  
  const columnHelper = createColumnHelper();
  const columns = [
    columnHelper.accessor("protocols_name", {
      header: getLabel("protocolName"),
      cell: TableCell,
      meta: {
        type: "text",
        filterVariant: 'text',
      },
    }),
    columnHelper.accessor("substance_name", {
        header: protocolType === "treatment"
            ? getLabel("treatmentName")
            : getLabel("analysisName"),
        cell: MultipleRowsCell,
        meta: {
            array: protocolType === "treatment" ? "drugs" : "analysis",
            protocols: translatedAnalysis,
            selector: "substance_name",
            filterVariant: 'text',
            // filterVariant: 'select',
        },
        filterFn: 'MultipleRowsFilterFn',
    }),  
      columnHelper.accessor("unit", {
        header: getLabel("unit"),
        cell: MultipleRowsCell,
        meta: {
            array: protocolType === "treatment" ? "drugs" : "analysis",
            selector: "unit",
            filterVariant: 'select',
            isFilterDisabled: true,
        },
    }),  
  ];

  const toggleDisplayMode = () => {
    dispatch(toggleProtocolsDisplayGrid());
  };

  const renderList = () => {
    if (protocols?.length > 0) {
      return (
        <div className="protocols_list_table" key={`key-${protocolType}`}>
          <TableEdit defaultData={protocols} columns={columns}
            rowClickHandler={(row) => {
              navigate(
                `/protocols/${protocolType}/${row.original.protocols_id}`
              );
            }}
            filterType={"protocols"}
            isLegend={protocolType !== "treatment"}
            isEditable={false}
          />
        </div>
      );
    }
  };

  const renderGrid = () => {
    if (protocols?.length > 0) {
      return (
        <div className="protocols_list_table" key={`key-${protocolType}`}>
        <TableEdit 
          isMobile={isMobile || displayModeGrid}
          defaultData={protocols}
          columns={columns}
          rowClickHandler={(row) => {
            navigate(
              `/protocols/${protocolType}/${row.original.protocols_id}`
            );
          }}
          filterType={"protocols"}
          isLegend={protocolType !== "treatment"}
          isEditable={false}
          type={protocolType}
        />
      </div>
      //   <div className="protocols_grid">
      //     {protocols.map((protocol) => {
      //       return <ProtocolTile {...protocol} />;
      //     })}
      //   </div>
      );
    }
  };

  return (
    <div className="protocols_list">
      {protocols?.length > 0 && (
        <>
          {!isMobile && <Switch
            leftOption={{
              label: getLabel("listView"),
            }}
            rightOption={{
              label: getLabel("gridView"),
            }}
            toggleHandler={toggleDisplayMode}
            status={displayModeGrid}
          />}
          {isMobile ? renderGrid() : displayModeGrid ? renderGrid() : renderList()}
        </>
      )}
      {!protocols ||
        (protocols?.length === 0 && (
          <>
            <div className="protocols_alert">
              <h6 className="protocols_alert_title">
                {getLabel("noProtocolsAlert", { type: getLabel(protocolType) })}
              </h6>
            </div>
          </>
        ))}
    </div>
  );
};

export default ProtocolList;
