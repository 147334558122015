import { NavLink } from "react-router-dom";
import useLabels from "../../hooks/useLabels";
import SecondaryBar from "../SecondaryBar";
import { CTA, InfoCard } from "../Generic";
import TankList from "../TankList";
import useAuth from "../../hooks/useAuth";
import { ROLES } from "../../constants/base";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectActiveWinery, selectTanks } from "../../context/selectors";
import { setTanks } from "../../context/tanks/tanksSlice";
import { getTanks } from "../../services/utils";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import "./styles/index.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import tank from "../../assets/icons/tank.png";
import barrique from "../../assets/icons/barrique.png";
import tonneau from "../../assets/icons/tonneau.png";
import autoclave from "../../assets/icons/autoclave.png";


const Tanks = ({ isMobile }) => {
  const [getLabel] = useLabels();
  const activeWinery = useSelector(selectActiveWinery);
  const dispatch = useDispatch();
  const axiosPrivate = useAxiosPrivate();
  const { auth } = useAuth();
  const role = auth?.role || ROLES.GUEST;
  const tanks = useSelector(selectTanks);
  const [tanksLen, setTanksLen] = useState({});

  const loadTanks = async () => {
    const currentTanks = await getTanks(activeWinery.id, axiosPrivate);
    dispatch(setTanks(currentTanks));
  };

  useEffect(() => {
    if (!tanks || tanks.length === 0) {
      loadTanks();
    }
  }, []);

  useEffect(() => {
    if (!tanks || tanks?.length === 0)
        return;

    const tmp = {};
    tmp.tot = tanks?.length || 0;
    const tt = tanks?.filter((t) => t?.type === "TANK");
    const tonneau = tanks?.filter((t) => t?.type === "TONNEAU");
    const barrique = tanks?.filter((t) => t?.type === "BARRIQUE");
    const autoclave = tanks?.filter((t) => t?.type === "AUTOCLAVE")
    
    tmp.tank = tt?.length;
    tmp.tonneau = tonneau?.length;
    tmp.barrique = barrique?.length;
    tmp.autoclave = autoclave?.length;

    tmp.tankss = {
      red: tt?.filter((t) => t?.color === "RED")?.length,
      white: tt?.filter((t) => t?.color === "WHITE")?.length,
      rose: tt?.filter((t) => t?.color === "ROSE")?.length,
      empty: tt?.filter((t) => Number(t?.quantity) === 0)?.length,
    };
    tmp.tonneaus = {
      red: tonneau?.filter((t) => t?.color === "RED")?.length,
      white: tonneau?.filter((t) => t?.color === "WHITE")?.length,
      rose: tonneau?.filter((t) => t?.color === "ROSE")?.length,
      empty: tonneau?.filter((t) => Number(t?.quantity) === 0)?.length,
    };
    tmp.barriques = {
      red: barrique?.filter((t) => t?.color === "RED")?.length,
      white: barrique?.filter((t) => t?.color === "WHITE")?.length,
      rose: barrique?.filter((t) => t?.color === "ROSE")?.length,
      empty: barrique?.filter((t) => Number(t?.quantity) === 0)?.length,
    };
    tmp.autoclaves = {
      red: autoclave?.filter((t) => t?.color === "RED")?.length,
      white: autoclave?.filter((t) => t?.color === "WHITE")?.length,
      rose: autoclave?.filter((t) => t?.color === "ROSE")?.length,
      empty: autoclave?.filter((t) => Number(t?.quantity) === 0)?.length,
    };
    setTanksLen(tmp);
  }, [tanks]);

  const getTooltipContent = (obj = {}) => {
    return (
    <ul>
      {Object.keys(obj)?.map((o) =>(<li className={`tooltip_label ${o === 'empty' ? 'empty' : ''}`}>{`${getLabel(o === 'empty' ? 'tankState_EMPTY' : `lotColor_${o?.toUpperCase()}`)}: ${obj?.[o]}`}</li>))}
    </ul>);
  }

  const renderNavMenu = () => {
    return (
      <div className="primary_container_menu">
        <h2>{getLabel("tanks")}</h2>
        <div>
          <h5 className="tanks_title">{getLabel("numTotTanks")}
          <span className="tanks_span">{tanksLen?.tot || "0"}</span></h5>
        </div>
        <div className="tanks_wrapper">
          {role === ROLES.CLIENT && (
            <NavLink to="/tanks/new" className="cta_wrapper">
              <CTA customClassName={'create'}>{getLabel("createTank")}{" "}<FontAwesomeIcon icon="fa-plus" /></CTA> 
            </NavLink>
          )}
          <div className="tanks_recap">
            <div className="tanks_recap_wrapper">
              <InfoCard label={getLabel("numTotTankTanks")} number={tanksLen?.tank} img={isMobile || true ? tank : null} isGrey={tanksLen?.tank === 0}
                content={getTooltipContent(tanksLen?.tankss)} />
              <InfoCard label={getLabel("numTotTonneauTanks")} number={tanksLen?.tonneau} img={isMobile || true ? tonneau : null} isGrey={tanksLen?.tonneau === 0}
                content={getTooltipContent(tanksLen?.tonneaus)} />
              <InfoCard label={getLabel("numTotBarriqueTanks")} number={tanksLen?.barrique} img={isMobile || true ? barrique : null} isGrey={tanksLen?.barrique === 0}
                content={getTooltipContent(tanksLen?.barriques)} />
              <InfoCard label={getLabel("numTotAutoclaveTanks")} number={tanksLen?.autoclave} img={isMobile || true ? autoclave : null} isGrey={tanksLen?.autoclave === 0}
                content={getTooltipContent(tanksLen?.autoclaves)} />
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="primary_container">
      <SecondaryBar breadCrumb={[getLabel("tanksNavLink")]} />
      {renderNavMenu()}
      <TankList isMobile={isMobile} />
    </div>
  );
};

export default Tanks;
