import { useNavigate, useParams } from "react-router-dom";
import * as XLSX from "xlsx";
import useLabels from "../../hooks/useLabels";
import { Button, WineMakingProtocolRowModal, CTA } from "../Generic";
import SecondaryBar from "../SecondaryBar";
import { useForm, useWatch } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  selectDrugs,
  selectUnits,
  selectWineMaking,
} from "../../context/selectors";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useEffect, useState } from "react";
import {
  getProtocols,
  getDrugs,
  updateProtocol,
  newProtocol,
  getUnits,
} from "../../services/utils";
import { Input, Select, Submit, TextArea } from "../FormComponents";
import { setDrugs } from "../../context/substances/drugsSlice";
import {
  ALPHA_NUM_SPACE_DASH_UNDERSCORE_REGEX,
  INT_0_REGEX,
  INT_FLOAT_REGEX,
  INT_REGEX,
} from "../../constants/regex";
import TableEdit from "../TableEdit";
import { createColumnHelper } from "@tanstack/react-table";
import EditCell from "../TableEdit/EditCell";
import TableCell from "../TableEdit/TableCell";
import { toast } from "react-toastify";
import {
  emptyWineMaking,
  setWineMaking,
} from "../../context/protocols/wineMakingSlice";

import "./styles/index.scss";
import { setUnits } from "../../context/units/unitsSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getItem } from "../../services/SessionStorage";

const WineMakingProtocolForm = ({}) => {
  const { type, id } = useParams();
  const [getLabel] = useLabels();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModifyMode, setIsModifyMode] = useState(-1);
  const [checkbox, setCheckbox] = useState(false);
  const units = useSelector(selectUnits);
  const drugs = useSelector(selectDrugs);
  const wineMakingOps = useSelector(selectWineMaking);
  const axiosPrivate = useAxiosPrivate();
  const dispatch = useDispatch();
  const [protocol, setProtocol] = useState(
    wineMakingOps?.length > 0
      ? wineMakingOps.find((dbProtocol) => dbProtocol.id === Number(id))
      : []
  );
  const [operationsP, setOperationsP] = useState(protocol?.operations || []);
  const customImport = "custom-import";

  const setCheckboxState = () => {
    setCheckbox(!checkbox);
  };

  const setModifyMode = (row_id) => {
    setIsModifyMode(row_id);
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    reset,
    setValue,
    getValues,
    setError,
    clearErrors,
    control,
  } = useForm({
    defaultValues: {
      name: protocol?.name || "",
      notes: protocol?.notes || "",
    },
    mode: "onTouched",
  });
  const { operation, interval } = useWatch({ control });

  const columnHelper = createColumnHelper();
  const columns = [
    columnHelper.accessor(
      (row) => row?.day + (row?.toDay ? ` - ${row?.toDay}` : ""),
      {
        header: getLabel("protocolDay"),
        cell: TableCell,
        meta: {
          type: "number",
          register: register,
        },
      }
    ),
    columnHelper.accessor(
      (row) => (row?.phase ? getLabel(`protocolPhase_${row.phase}`) : ""),
      {
        header: getLabel("protocolPhase"),
        cell: TableCell,
        // meta: {
        //   type: "text",
        // },
        meta: {
          type: "select",
          options: [
            { value: "Computer Science", label: "Computer Science" },
            { value: "Communications", label: "Communications" },
            { value: "Business", label: "Business" },
            { value: "Psychology", label: "Psychology" },
          ],
        },
      }
    ),
    columnHelper.accessor(
      (row) =>
        row?.operation ? getLabel(`protocolOperation_${row.operation}`) : "",
      {
        header: getLabel("protocolOperation"),
        cell: TableCell,
        meta: {
          type: "text",
          register: register,
        },
      }
    ),
    columnHelper.accessor((row) => row?.operation === 'SO2' ? '' : row?.product_name || "", {
      header: getLabel("protocolProduct"),
      cell: TableCell,
      meta: {
        type: "text",
        register: register,
      },
    }),
    columnHelper.accessor(
      (row) =>
        row?.quantity
          ? row?.protocolType === "HOMOGENIZATION_TEMP"
            ? `${row?.quantity} \u00b0C`
            : (row?.quantity || 0) +
              " " +
              (row?.product?.unit || row?.product_unit || "")
              //  +
              // (row?.protocolType === "MICRO"
              //   ? getLabel("protocolOxygenationMonth")
              //   : row?.protocolType === "MACRO"
              //   ? getLabel("protocolOxygenationDay")
              //   : row?.protocolType === "CUSTOM"
              //   ? `/${row?.oxygenationDoseHours}h:${row?.oxygenationDoseMinutes}m`
              //   : "")
          : "",
      {
        header: getLabel("protocolQuantity"),
        cell: TableCell,
        meta: {
          type: "number",
          register: register,
        },
      }
    ),
    // columnHelper.accessor("unit_id", {
    //   header: getLabel("unit"),
    //   cell: TableCell,
    //   meta: {
    //     type: "text",
    //     register: register,
    //   },
    // }),
    // columnHelper.accessor("cost", {
    //   header: getLabel("protocolCost"),
    //   cell: TableCell,
    //   meta: {
    //     type: "number",
    //   },
    // }),
    columnHelper.accessor(
      (row) =>
        row?.protocolType
          ? getLabel(`protocolType_${row?.protocolType}`) +
            (row?.protocolType === "HOMOGENIZATION_REASSEMBLY" &&
            row?.homogenizationType
              ? ` (${getLabel(
                  `protocolHomogenizationMass_${row?.homogenizationType}`
                )})`
              : "") +
              (row?.protocolType === "CUSTOM"
                ? ` (${row?.oxygenationDoseHours}h:${row?.oxygenationDoseMinutes}m)`
                : "")
          : "",
      {
        header: getLabel("protocolType"),
        cell: TableCell,
        meta: {
          type: "text",
          register: register,
          // placeholder: getLabel("lotColorPlaceholder"),
        },
      }
    ),
    columnHelper.accessor(
      (row) => (row?.workTime && (row?.pauseTime || row?.homogenizationPauseTime) ? 
        `${row?.workTime}min / ${row?.pauseTime || row?.homogenizationPauseTime}h` : ""),
      {
        header: getLabel("protocolWorkPauseTime"),
        cell: TableCell,
        meta: {
          type: "number",
          register: register,
        },
      }
    ),
    // columnHelper.accessor(
    //   (row) =>
    //     row?.homogenizationPauseTime ? row?.homogenizationPauseTime + " h" : "",
    //   {
    //     header: getLabel("protocolPauseTime"),
    //     cell: TableCell,
    //     meta: {
    //       type: "number",
    //       register: register,
    //     },
    //   }
    // ),
    columnHelper.accessor(
      (row) =>
        row?.homogenizationMotorSpeed
          ? row?.homogenizationMotorSpeed + " %"
          : "",
      {
        header: getLabel("protocolMotorSpeed"),
        cell: TableCell,
        meta: {
          type: "number",
          register: register,
        },
      }
    ),
    columnHelper.accessor("iterPerDay", {
      header: getLabel("protocolIterationPerDay"),
      cell: TableCell,
      meta: {
        type: "number",
        register: register,
      },
    }),
    // columnHelper.accessor("oxygenationType", {
    //   header: getLabel("protocolOxygenationType"),
    //   cell: TableCell,
    //   meta: {
    //     type: "text",
    //     register: register,
    //   },
    // }),
    columnHelper.display({
      id: "edit",
      cell: EditCell,
      meta: {
        isEditable: true,
      },
    }),
  ];
  useEffect(() => {
    if (!wineMakingOps || wineMakingOps.length === 0) {
      loadProtocols();
    }
    if (!drugs || drugs.length === 0) {
      loadDrugs();
    }

    if (!units || units.length === 0) {
      loadUnits();
    }
  }, []);

  useEffect(() => {
    if (
      id &&
      wineMakingOps.length > 0 &&
      Object.keys(operationsP).length === 0
    ) {
      const singleProtocol =
        wineMakingOps.find((dbProtocol) => dbProtocol.id === Number(id)) ||
        null;
      if (singleProtocol) {
        setOperationsP(singleProtocol?.operations);
        setValue("name", singleProtocol?.name || "");
        setValue("notes", singleProtocol?.notes || "");
      }
    }
  }, [wineMakingOps]);

  const loadProtocols = async () => {
    const currentProtocols = await getProtocols("winemaking", axiosPrivate);
    dispatch(setWineMaking(currentProtocols));
  };

  const loadDrugs = async () => {
    const currentDrugs = await getDrugs(axiosPrivate);
    dispatch(setDrugs(currentDrugs));
  };

  const loadUnits = async () => {
    const currentUnits = await getUnits(axiosPrivate);
    dispatch(setUnits(currentUnits));
  };

  const submitForm = async (data) => {
    // console.log(data);
    // return;
    // console.log(protocol);

    let toastId = null;
    if (
      !operationsP ||
      operationsP.length === 0 ||
      (data?.notes === operationsP.notes &&
        data?.name === operationsP.protocols_name)
    ) {
      toastId = toast.info(getLabel("toast_NoModifications"), {
        position: toast.POSITION.BOTTOM_RIGHT,
        exclude: true,
      });
      return;
    }
    const operations = [];
    operationsP.forEach((op) => {
      const data = {
        day: op.day,
        iterPerDay: op.iterPerDay,
        workTime: op.workTime,
      };
      for (const [key, value] of Object.entries(op)) {
        if (
          typeof value === "object" &&
          !Array.isArray(value) &&
          value !== null
        ) {
          // console.log('value', key, value, typeof value === 'object', !Array.isArray(value), value !== null)
          data[key] = value?.value;
        } else data[key] = value;
      }
      operations.push(data);
    });

    const requestPayload = {
      type: type,
      name: data?.name,
      operations: operations,
      notes: data?.notes,
      ...(id && { id: Number(id) }),
    };

    console.log("requestPayload", requestPayload?.operations);
    // return;
    toastId = toast.loading(getLabel("toast_inProgress"), {
      type: toast.TYPE.INFO,
      position: toast.POSITION.BOTTOM_RIGHT,
      exclude: true,
    });
    let response = null;

    if (id) {
      response = await updateProtocol(
        id,
        "winemaking",
        requestPayload,
        axiosPrivate
      );
    } else {
      response = await newProtocol("winemaking", requestPayload, axiosPrivate);
    }
    console.log(response);
    toast.update(toastId, {
      render: response?.success
        ? getLabel(response?.success, { name: data?.name || "" })
        : getLabel(response?.error),
      type: response?.error ? toast.TYPE.ERROR : toast.TYPE.SUCCESS,
      isLoading: false,
      position: toast.POSITION.BOTTOM_RIGHT,
      autoClose: 4000,
    });
    // console.log(response);
    if (response && response?.success) {
      updateStore();
      // navClickHandler();
    }
  };

  const updateStore = () => {
    dispatch(emptyWineMaking());
  };

  const navClickHandler = () => {
    if (id) {
      navigate(`/protocols/winemaking/${id}`);
    } else {
      navigate(`/protocols/winemaking`);
    }
  };

  const renderNavMenu = () => {
    const navClickHandler = () => {
      if (id) {
        navigate(`/protocols/winemaking/${type}/${id}`);
      } else {
        navigate(`/protocols/winemaking/${type}`);
      }
    };

    return (
      <div className="primary_container_menu">
        <div className="primary_container_row">
          <Button arrowDirection="left" onClick={navClickHandler} />
          <h2>
            {getLabel(
              type === "PRE"
                ? id
                  ? "wineMakingProtocolsUpdatePage"
                  : "wineMakingProtocolsNewPage"
                : id
                ? "wineMakingPostProtocolsUpdatePage"
                : "wineMakingPostProtocolsNewPage",
              {
                type: "",
                name: operationsP.protocols_name || "",
              }
            )}
          </h2>
        </div>
      </div>
    );
  };

  const addNewRow = () => {
    const requiredError = {
      type: "manual",
      message: getLabel("inputRequiredError"),
    };
    console.log("val", getValues());
    console.log("errors", errors);
    const values = getValues();

    if (values?.day) clearErrors("day");
    if (values?.operation) clearErrors("operation");
    if (values?.phase) clearErrors("phase");

    if (!values?.day || !values?.operation?.value || !values?.phase?.value) {
      if (!values?.day) setError("day", requiredError);
      if (!values?.operation) setError("operation", requiredError);
      if (!values?.phase) setError("phase", requiredError);
      return;
    }

    let newOperations = [...operationsP];
    const newOperation = {
      day: Number(values?.day),
      phase: values?.phase?.value,
      operation: values?.operation?.value,
      goal: values?.goal,
      note: values?.note,
    };
    if (
      !["OXYGENATION", "DECANT", "RACKING", "HOMOGENIZATION_MASS"].includes(
        values?.operation?.value
      )
    ) {
      newOperation.product = values?.product?.id;
      newOperation.product_name = values?.product?.name;
      newOperation.product_unit = values?.product?.unit;
      newOperation.product_unit_id = units?.find(
        (d) => d?.unit === values?.product?.unit
      )?.id;
      newOperation.quantity = values?.quantity;
      newOperation.protocolType = "ADD";

      if ("SO2" === values?.operation?.value) {
        newOperation.product = "protocolProduct_SO2";
        newOperation.product_name = getLabel("protocolProduct_SO2");
        newOperation.product_unit = "g/hL";
        newOperation.product_unit_id = 2;
      }

      if (!values?.product && "SO2" !== values?.operation?.value) {
        setError("product", requiredError);
        return;
      } else clearErrors("product");
      if (!values?.quantity) {
        setError("quantity", requiredError);
        return;
      } else clearErrors("quantity");
    } else if ("HOMOGENIZATION_MASS" === values?.operation?.value) {
      if (values?.protocolType?.value === "HOMOGENIZATION_REASSEMBLY") {
        newOperation.homogenizationType = values?.homogenizationType?.value;
        newOperation.workTime = values?.homogenizationWorkTime;
        newOperation.homogenizationPauseTime = values?.homogenizationPauseTime;
        newOperation.homogenizationMotorSpeed =
          values?.homogenizationMotorSpeed;
        newOperation.iterPerDay = values?.homogenizationIterPerDay;
        newOperation.protocolType = values?.protocolType?.value;
        newOperation.workTimeUnit = "min";
        newOperation.pauseTimeUnit = "h";

        if (!values?.homogenizationType) {
          setError("homogenizationType", requiredError);
          return;
        } else clearErrors("homogenizationType");
        // if (!values?.homogenizationTemp) {
        //   setError("homogenizationTemp", requiredError);
        //   return;
        // } else
        clearErrors("homogenizationTemp");
        if (!values?.homogenizationWorkTime) {
          setError("homogenizationWorkTime", requiredError);
          return;
        } else clearErrors("homogenizationWorkTime");
        if (!values?.homogenizationPauseTime) {
          setError("homogenizationPauseTime", requiredError);
          return;
        } else clearErrors("homogenizationPauseTime");
        if (!values?.homogenizationMotorSpeed) {
          setError("homogenizationMotorSpeed", requiredError);
          return;
        } else clearErrors("homogenizationMotorSpeed");
        if (!values?.homogenizationIterPerDay) {
          setError("homogenizationIterPerDay", requiredError);
          return;
        } else clearErrors("homogenizationIterPerDay");

        if (
          Number(values?.homogenizationIterPerDay) *
            (60 * Number(values?.homogenizationPauseTime)) +
            Number(values?.homogenizationWorkTime) >
          24 * 60
        ) {
          setError("homogenizationIterPerDay", {
            type: "manual",
            message: getLabel("errorProtocolHomogenization"),
          });
          return;
        } else clearErrors("homogenizationIterPerDay");
      } else if (values?.protocolType?.value === "HOMOGENIZATION_TEMP") {
        if (errors?.homogenizationTemp) {
          return;
        }
        newOperation.protocolType = values?.protocolType?.value;
        newOperation.quantity = values?.homogenizationTemp;
      }
    } else if ("OXYGENATION" === values?.operation?.value) {
      newOperation.quantity = values?.oxygenationDose;
      newOperation.homogenizationType = values?.homogenizationType;
      // newOperation.workTime = values?.homogenizationWorkTime;
      // newOperation.iterPerDay = values?.oxygenationIterPerDay;
      newOperation.protocolType = values?.oxigenationType?.value;
      newOperation.product_unit = "mg/L";
      if ("CUSTOM" === values?.oxigenationType?.value) {
        newOperation.oxygenationDoseHours = values?.oxygenationDoseHours;
        newOperation.oxygenationDoseMinutes = values?.oxygenationDoseMinutes;

        if (!values?.oxygenationDoseHours) {
          setError("oxygenationDoseHours", requiredError);
          return;
        } else clearErrors("oxygenationDoseHours");
        if (!values?.oxygenationDoseMinutes) {
          setError("oxygenationDoseMinutes", requiredError);
          return;
        } else clearErrors("oxygenationDoseMinutes");
      }
    } else if (["DECANT", "RACKING"].includes(values?.operation?.value)) {
      newOperation.protocolType = "MANUAL";
    }

    if (checkbox && ["DECANT", "RACKING"].includes(values?.operation?.value)) {
      clearErrors("toDay");
    } else if (checkbox) {
      if (!values?.toDay) {
        setError("toDay", requiredError);

        return;
      } else if (values?.toDay <= values?.day) {
        setError("toDay", {
          value: Number(values?.day) + 1,
          message: getLabel("errorMinValue", {
            value: Number(values?.day) + 1,
          }),
        });
        return;
      } else clearErrors("toDay");
      newOperation.toDay = values?.toDay;
    } else if (!checkbox && errors?.toDay) {
      clearErrors("toDay");
    }

    if (errors && Object.keys(errors)?.length > 0) {
      return;
    }

    delete newOperation["opType"];
    if (isModifyMode >= 0) {
      setIsModifyMode(-1);
      newOperations[isModifyMode] = newOperation;
    } else {
      newOperations.push(newOperation);
    }

    newOperations = newOperations?.sort((a, b) =>
      a.day > b.day ? 1 : b.day > a.day ? -1 : 0
    );
    setOperationsP(newOperations);

    // reset the modal fields;
    setCheckbox(false);
    setValue("toDay", "");
    setValue("operation", {
      label: "",
      value: "",
    });
    if (values?.operation?.value !== "HOMOGENIZATION_MASS")
      setValue("protocolType", {
        label: "",
        value: "",
      });
    setValue("product", {
      label: "",
      value: "",
    });
    setValue("quantity", "");
    setValue("homogenizationType", {
      label: "",
      value: "",
    });
    setValue("homogenizationTemp", "");
    setValue("homogenizationWorkTime", "");
    setValue("homogenizationPauseTime", "");
    setValue("homogenizationMotorSpeed", "");
    setValue("homogenizationIterPerDay", "");
    setValue("oxigenationType", {
      label: "",
      value: "",
    });
    // setValue("oxygenationIterPerDay", "");
    setValue("goal", "");
    setValue("note", "");

    if (!errors || Object.keys(errors)?.length === 0) {
      setIsModalOpen(false);
    } else {
      return;
    }
  };

  const [file, setFile] = useState(null);

  const handleExport = () => {
    const data = [];
    const language = getItem('i18LanguageLocale') || 'it';
    const headers = [];
    switch (language) {
      // TO DO falle diventare labels
      case "en":
        headers.push(...['DAY', 'PHASE', 'OPERATION', 'OP. TYPE', 'OENOLOGICAL PRODUCT', "QUANTITY", 'UNIT', 'COST', 'TYPE', 'WORK TIME (min)', 'PAUSE TIME (hh)', "MOTOR SPEED (% )", 'NUMBER x DAY (hh)', 'CUSTOM OXYG. HOURS (hh)', 'CUSTOM OXIG. MINUTES (min)', 'NOTE', 'GOAL'])
        break;
      default:
        headers.push(...['GIORNO', 'FASE', 'OPERAZIONE', 'TIPO OP.', 'PRODOTTO ENOLOGICO', "QUANTITA'", "UNITA'", 'COSTO', 'TIPO', 'TEMPO LAVORO (min)', 'TEMPO PAUSA (hh)', "VELOCITA' MOTORE (%)", 'NUMERO x GIORNO (hh)', 'ORE OSSIG. PERSON. (hh)', 'MINUTI OSSIG. PERSON. (min)', 'NOTE', 'OBIETTIVI'])
      }
    
    const worksheet = XLSX.utils.json_to_sheet(data);
    // var worksheet = XLSX.utils.aoa_to_sheet([
    //   [ '' ], // A1
    //   [ {t: "n", f: "A2+A3"}, {t: "s", f: 'CHOOSE("","red","blue","green")'} ] // A2
    // ]);
    
    const wscols = [
      {wch: 10},
      {wch: 15},
      {wch: 13},
      {wch: 10},
      {wch: 20},
      {wch: 10},
      {wch: 10},
      {wch: 10},
      {wch: 20},
      {wch: 20},
      {wch: 20},
      {wch: 20},
      {wch: 18},
      {wch: 25},
      {wch: 25},
      {wch: 25},
      {wch: 25},
    ];
    worksheet['!cols'] = wscols;
    XLSX.utils.sheet_add_aoa(worksheet, [headers], { origin: "A1" });
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(
      workbook,
      `ANALYSIS_.xlsx`,
      { compression: true }
    );
  };

  const buildJson = (json) => {
    const data = [];
    let error = null;
    json?.forEach((row) => {
      const rowArr = Object.values(row) || [];
      const tmp = {
        day: rowArr?.[0] || 0, //row?.['GIORNO'] || row?.['DAY'] || 0,
        // phase: rowArr?.[1], //row?.['FASE'] || row?.['PHASE'],
        // operation: rowArr?.[2], //row?.['OPERAZIONE'] || row?.['OPERATION'],
        // protocolType: rowArr?.[3], //row?.['TIPO OP.'] || row?.['OP. TYPE'] || row?.['TIPO OSSIGENAZ.'] || row?.['OXYGENAT. TYPE'],
        quantity: rowArr?.[5], //row?.["QUANTITA'"] || row?.['QUANTITY'] || row?.['DOSE (mg/lt)'],
        note: rowArr?.[17], //row?.['NOTE'] || row?.['NOTE'],
        goal: rowArr?.[18], //row?.['OBIETTIVI'] || row?.['GOAL'],
        // type: row?.['TIPO'] || row?.['TYPE'],
      }
      if (row?.day?.split('-')?.length === 2) tmp.toDay = row?.day?.split('-')?.[2];

      // phase
      const phase = rowArr?.[1];
      if (['Fermentazione', 'Fermentation'].includes(phase)) tmp.phase = 'FERM';
      if (['Pre-fermentazione', 'Pre-fermentation'].includes(phase)) tmp.phase = 'PREFERM';
      if (['Post-fermentazione', 'Post-fermentation'].includes(phase)) tmp.phase = 'POSTFERM';
      if (['Inoculo', 'Inoculation'].includes(phase)) tmp.phase = 'INOCULATE';
      if (['Inizio', 'Start'].includes(phase)) tmp.phase = 'START';
      if (['Pre-fermentazione', 'Pre-fermentation'].includes(phase)) tmp.phase = 'PREFERM';
      if (['Fermentazione alcolica', 'Alcoholic fermentation'].includes(phase)) tmp.phase = 'FERMAL';
      if (['Fermentazione malolattica', 'Malo-lactic fermentation'].includes(phase)) tmp.phase = 'FERMML';
      if (['Fine fermentazione malolattica', 'Malo-lactic fermentation end'].includes(phase)) tmp.phase = 'ENDFERMML';
      
      // operation
      const operation = rowArr?.[2];
      if (['Tennissage'].includes(operation)) tmp.operation = 'TENNISSAGE';
      if (['Bois'].includes(operation)) tmp.operation = 'BOIS';
      if (['Enzima'].includes(operation)) tmp.operation = 'ENZYME';
      if (['Anidride solforosa', 'Sulfur dioxide'].includes(operation)) tmp.operation = 'SO2';
      if (['Omogeneizzazione massa', 'Mass homogenization'].includes(operation)) tmp.operation = 'HOMOGENIZATION_MASS';
      if (['Temperatura', 'Temperature'].includes(operation)) tmp.operation = 'HOMOGENIZATION_TEMP';
      if (['Rimontaggio', 'Reassembly'].includes(operation)) tmp.operation = 'REASSEMBLY';
      if (['Ossigenazione', 'Oxygenation'].includes(operation)) tmp.operation = 'OXYGENATION';
      if (['Nutrizione azotata', 'Nitrogenous nutrition'].includes(operation)) tmp.operation = 'NITROGEN';
      if (['Inoculo', 'Inoculation'].includes(operation)) tmp.operation = 'INOCULATION';
      if (['Inoculo batteri', 'Bacteria inoculation'].includes(operation)) tmp.operation = 'BACTERIA';
      
      // protocolType
      const protocolType = rowArr?.[3];
      if (['Aggiunta', 'Add'].includes(protocolType)) {
        tmp.protocolType = 'ADD';
        tmp.product_name = row?.['PRODOTTO ENOLOGICO'] || row?.['OENOLOGICAL PRODUCT'];
        tmp.rowArr = row?.[7]; //row?.['COSTO'] || row?.['COST']
        const foundDrug = drugs?.find((d) => d?.name === tmp?.product_name) || null;
        if (foundDrug !== null) {   
          tmp.product = foundDrug?.id;
          tmp.product_unit = foundDrug?.unit;
          tmp.product_unit_id = foundDrug?.unit_id;
        } else {
          error = {
            product_name: tmp?.product_name,
            type: 'ADD'
          };
        }
      };
      
      if (['Rimontaggio', 'Reassembly'].includes(protocolType)) {
        tmp.quantity = '';
        tmp.protocolType = 'HOMOGENIZATION_REASSEMBLY';
        tmp.homogenizationMotorSpeed = row?.["VELOCITA' MOTORE (%)"] || row?.['MOTOR SPEED (%)'];
        tmp.homogenizationType = (['Chiuso', 'Closed'].includes(row?.["TIPO RIMONTAGGIO"] || row?.['REASSEMBLY TYPE'])) ? 'CLOSED' : '';
        tmp.homogenizationPauseTime = row?.['TEMPO LAVORO (min)'] || row?.['WORK TIME (min)'];
        tmp.workTime = row?.['TEMPO LAVORO (min)'] || row?.['WORK TIME (min)'];
        tmp.workTimeUnit = "min";
        tmp.pauseTime = row?.['TEMPO PAUSA (hh)'] || row?.['PAUSE TIME (min)'];
        tmp.pauseTimeUnit = "h";
        tmp.iterPerDay = row?.['NUMERO x GIORNO (hh)'] || row?.['NUMBER x DAY (hh)'];
      } else if (['Temperatura', 'Temperature'].includes(protocolType)) {
        tmp.protocolType = 'HOMOGENIZATION_TEMP';
        tmp.product_unit = '\u00b0C';
        tmp.quantity = rowArr?.[4];
      }
      if (tmp.operation === "OXYGENATION") {
        tmp.oxygenationDoseHours = row?.['ORE OSSIG. PERSON. (hh)'] || row?.['CUSTOM OXIG. HOURS (hh)'];
        tmp.oxygenationDoseMinutes = row?.['MINUTI OSSIG. PERSON. (min)'] || row?.['CUSTOM OXIG. MINUTES (min)'];
        if (tmp.oxygenationDoseHours) tmp.protocolType = '';
        const prType = rowArr?.[6]; //row?.['TIPO OSSIGENAZ.'] || row?.['OXYGENATION TYPE']; 
        tmp.protocolType = prType === 'Macro' ? 'MACRO' : ['Personalizzata', 'Custom'].includes(prType) ? 'CUSTOM' : 'MICRO';
        tmp.quantity = rowArr?.[4]; // is 4 because '' are not in the rowArr
        tmp.product_unit = row?.[5] || 'mg/L';
        tmp.product_unit_id = 1;
      }
      // TO DO manca sicuramente roba
      // TO DO aggiungi check correttezza dati
      
      data.push(tmp);
    });

    return error?.type ? error : data;
  }

  const handleConvert = () => {
    const toastId = toast.loading(getLabel("toast_inProgress"), {
      type: toast.TYPE.INFO,
      position: toast.POSITION.BOTTOM_RIGHT,
      exclude: true,
      isLoading: true,
    });
  
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = e.target.result;
        const workbook = XLSX.read(data, { type: "binary" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const json = XLSX.utils.sheet_to_json(worksheet);
        
        const protocolJson = buildJson(json.sort((a, b) => (a?.['GIORNO'] || a?.['DAY']) - (b?.['GIORNO'] || b?.['DAY'])));
        
        if (typeof(protocolJson) === 'object') {
          toast.update(toastId, {
            render: getLabel(`toast_importWinemakingProtocolErrorType_${protocolJson?.type}`, {value: protocolJson?.product_name}),
            type: toast.TYPE.ERROR,
            isLoading: false,
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 4000,
          });
          return;
        }

        if (protocolJson !== null) {
          setOperationsP(protocolJson);
        }
        // generic error
        toast.update(toastId, {
          render: protocolJson === null
            ? getLabel('toast_importWinemakingProtocolError')
            : getLabel('toast_importWinemakingProtocolSuccess'),
          type: protocolJson === null ? toast.TYPE.ERROR : toast.TYPE.SUCCESS,
          isLoading: false,
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 4000,
        });
      };
      reader.readAsBinaryString(file);
    }
  };

  const getPhasesByType = () => {
    const phases = [];
    if (type === "PRE")
      phases.push(
        ...[
          {
            label: getLabel("protocolPhase_PREFERM"),
            value: "PREFERM",
          },
          {
            label: getLabel("protocolPhase_INOCULATE"),
            value: "INOCULATE",
          },
          {
            label: getLabel("protocolPhase_START"),
            value: "START",
          },
          {
            label: getLabel("protocolPhase_FERMAL"),
            value: "FERMAL",
          },
          {
            label: getLabel("protocolPhase_FERMML"),
            value: "FERMML",
          },
        ]
      );
    else if (type === "POST")
      phases.push(
        ...[
          {
            label: getLabel("protocolPhase_INOCULATE"),
            value: "INOCULATE",
          },
          {
            label: getLabel("protocolPhase_ENDFERMML"),
            value: "ENDFERMML",
          },
          {
            label: getLabel("protocolPhase_POSTFERM"),
            value: "POSTFERM",
          },
        ]
      );
    return phases;
  };

  const renderForm = () => {
    return (
      <div className="protocol_form_wrapper">
        <form onSubmit={handleSubmit(submitForm)} autoComplete="off" noValidate>
          <div className="protocol_form_wrapper_row">
            <Input
              name="name"
              register={register}
              type="text"
              label={getLabel("protocolName")}
              placeholder={getLabel("protocolNamePlaceholder")}
              error={errors["name"]}
              required={getLabel("inputRequiredError")}
              regExpPattern={{
                value: ALPHA_NUM_SPACE_DASH_UNDERSCORE_REGEX,
                message: getLabel("errorAlphaNumSpace_-"),
              }}
              minLength={{
                value: 3,
                message: getLabel("errorMinLength", { value: 3 }),
              }}
              maxLength={{
                value: 32,
                message: getLabel("errorMaxLength", { value: 32 }),
              }}
              tooltip={getLabel("protocolNameTooltip")}
            />
          </div>
          <div className="protocol_form_wrapper_row">
            <Input
              name="file"
              register={register}
              type="file"
              label={getLabel("operationExcelImportFile")}
              // placeholder={getLabel("protocolNamePlaceholder")}
              error={errors["file"]}
              tooltip={getLabel("operationExcelImportTooltip")}
              accept=".xls,.xlsx"
              onChange={(e) => setFile(e.target.files[0])}
            />
            {<CTA disabled={file === null} onClick={handleConvert}>
              {getLabel("operationExcelImport")}{" "}<FontAwesomeIcon icon="fa-file-excel" />
            </CTA>}
            <CTA onClick={handleExport}>
              {getLabel("operationExcelTemplateExport")}{" "}<FontAwesomeIcon icon="fa-file-arrow-down" />
            </CTA>
          </div>
          <div className="protocol_form_wrapper_row vertical">
            <h4 className="">{getLabel("wineMakingProtocolSubstances")}</h4>
            <TableEdit
              defaultData={
                operationsP?.map((s) => {
                  return {
                    ...s,
                    opType: "WINEMAKINGPROTOCOL",
                  };
                }) || []
              }
              setRows={setOperationsP}
              columns={columns}
              errors={errors}
              control={control}
              isEditable={true}
              isSearchBar={false}
              setValue={setValue}
              type={"winemaking"}
              modalHandler={handleOpenModal}
              setModifyMode={setModifyMode}
              newRow={{
                substance_name: "",
                min_range: "",
                max_range: "",
                min_temperature: "",
                max_temperature: "",
                unit: "",
                quantity: "",
                type: "",
              }}
            />
          </div>
          <div className="protocol_form_wrapper_row">
            <TextArea
              name="notes"
              register={register}
              label={getLabel("protocolNotes")}
              placeholder={getLabel("protocolNotesPlaceholder")}
              error={errors["notes"]}
              maxLength={{
                value: 200,
                message: getLabel("errorMaxLength", { value: 200 }),
              }}
              // tooltip={getLabel("protocolNotesTooltip")}
            />
          </div>
          <div className="protocol_form_wrapper_row">
            <Submit label={getLabel("submitForm")} />
          </div>
        </form>
        <WineMakingProtocolRowModal
          isOpen={isModalOpen}
          onConfirm={addNewRow}
          onClose={handleCloseModal}
          description={getLabel("modalLogout")}
        >
          <div className="operation_info_container_row">
            <Input
              name="day"
              register={register}
              type="number"
              label={
                interval
                  ? getLabel("protocolDayInterval")
                  : getLabel("protocolDay")
              }
              placeholder={getLabel("protocolDayPlaceholder")}
              error={errors["day"]}
              min={{
                value: 0,
                message: getLabel("errorMinValue", { value: 0 }),
              }}
              max={{
                value: 50,
                message: getLabel("errorMaxValue", { value: 50 }),
              }}
              regExpPattern={{
                value: INT_0_REGEX,
                message: getLabel("errorInt"),
              }}
              tooltip={getLabel("protocolDayTooltip")}
            />
            {getValues("operation")?.value &&
              !["DECANT", "RACKING"].includes(
                getValues("operation")?.value
              ) && (
                <>
                  {interval && (
                    <Input
                      name="toDay"
                      register={register}
                      type="number"
                      label={getLabel("protocolToDay")}
                      placeholder={getLabel("protocolToDayPlaceholder")}
                      tooltip={getLabel("protocolToDayTooltip")}
                      error={errors["toDay"]}
                      min={{
                        value: Number(getValues("day")) + 1,
                        message: getLabel("errorMinValue", {
                          value: Number(getValues("day")) + 1,
                        }),
                      }}
                      regExpPattern={{
                        value: INT_0_REGEX,
                        message: getLabel("errorInt"),
                      }}
                    />
                  )}
                  <Input
                    name={`interval`}
                    register={register}
                    label={getLabel(`protocolIntervalOp`)}
                    errors={errors["interval"]}
                    type={"checkbox"}
                    onChange={setCheckboxState}
                    // checked={getValues("interval")}
                    checked={getValues("interval")}
                    customClassName={"winemaking"}
                  />
                </>
              )}
            <Select
              name="phase"
              control={control}
              label={getLabel("protocolPhase")}
              placeholder={getLabel("protocolPhasePlaceholder")}
              errors={errors["phase"]}
              required={getLabel("inputRequiredError")}
              options={getPhasesByType()}
            />
          </div>
          <div className="operation_info_container_row">
            <Select
              name="operation"
              control={control}
              label={getLabel("protocolOperation")}
              placeholder={getLabel("protocolOperationPlaceholder")}
              errors={errors["operation"]}
              required={getLabel("inputRequiredError")}
              options={[
                {
                  label: getLabel("protocolOperation_TENNISSAGE"),
                  value: "TENNISSAGE",
                },
                {
                  label: getLabel("protocolOperation_BOIS"),
                  value: "BOIS",
                },
                {
                  label: getLabel("protocolOperation_ENZYME"),
                  value: "ENZYME",
                },
                {
                  label: getLabel("protocolOperation_SO2"),
                  value: "SO2",
                },
                {
                  label: getLabel("protocolOperation_HOMOGENIZATION_MASS"),
                  value: "HOMOGENIZATION_MASS",
                },
                {
                  label: getLabel("protocolOperation_OXYGENATION"),
                  value: "OXYGENATION",
                },
                {
                  label: getLabel("protocolOperation_NITROGEN"),
                  value: "NITROGEN",
                },
                {
                  label: getLabel("protocolOperation_INOCULATION"),
                  value: "INOCULATION",
                },
                {
                  label: getLabel("operationTypeRACKING"),
                  value: "RACKING",
                },
                {
                  label: getLabel("operationTypeDECANT"),
                  value: "DECANT",
                },
              ]}
            />
            {getValues("operation")?.value &&
              "HOMOGENIZATION_MASS" === getValues("operation")?.value && (
                <>
                  <Select
                    name="protocolType"
                    control={control}
                    label={getLabel("protocolType")}
                    placeholder={getLabel("protocolTypePlaceholder")}
                    errors={errors["protocolType"]}
                    required={getLabel("inputRequiredError")}
                    options={[
                      {
                        label: getLabel(
                          "protocolOperation_HOMOGENIZATION_TEMP"
                        ),
                        value: "HOMOGENIZATION_TEMP",
                      },
                      {
                        label: getLabel(
                          "protocolOperation_HOMOGENIZATION_REASSEMBLY"
                        ),
                        value: "HOMOGENIZATION_REASSEMBLY",
                      },
                    ]}
                    defaultValue={{
                      label: getLabel("protocolOperation_HOMOGENIZATION_TEMP"),
                      value: "HOMOGENIZATION_TEMP",
                    }}
                  />
                  {getValues("protocolType")?.value &&
                    "HOMOGENIZATION_TEMP" ===
                      getValues("protocolType")?.value &&
                    getValues("operation")?.value === "HOMOGENIZATION_MASS" && (
                      // <>
                      <Input
                        name="homogenizationTemp"
                        register={register}
                        type="number"
                        label={getLabel("protocolHomogenizationTemp")}
                        placeholder={getLabel(
                          "protocolHomogenizationTempPlaceholder"
                        )}
                        error={errors["homogenizationTemp"]}
                        min={{
                          value: 1,
                          message: getLabel("errorMinValue", { value: 1 }),
                        }}
                        max={{
                          value: 40,
                          message: getLabel("errorMaxValue", { value: 40 }),
                        }}
                        regExpPattern={{
                          value: INT_FLOAT_REGEX,
                          message: getLabel("errorIntFloat"),
                        }}
                      />
                      // </>
                    )}
                </>
              )}
            {getValues("operation")?.value &&
              ![
                "OXYGENATION",
                "DECANT",
                "RACKING",
                "HOMOGENIZATION_MASS",
              ].includes(getValues("operation")?.value) && (
                <>
                  {getValues("operation")?.value !== "SO2" && (
                    <Select
                      name="product"
                      control={control}
                      label={getLabel("protocolProduct")}
                      placeholder={getLabel("protocolProductPlaceholder")}
                      errors={errors["product"]}
                      required={getLabel("inputRequiredError")}
                      options={drugs?.map((d) => {
                        return {
                          ...d,
                          label:
                            d?.name + (d?.unit ? " (" + d?.unit + ")" : ""),
                        };
                      })}
                    />
                  )}
                  {getValues("operation")?.value === "SO2" && (
                    <Input
                      name="product"
                      isDisabled={true}
                      register={register}
                      type="text"
                      defaultValue={getLabel("protocolProduct_SO2") + " (g/hL)"}
                      label={getLabel("protocolProduct")}
                      placeholder={getLabel("protocolProductPlaceholder")}
                      error={errors["product"]}
                    />
                  )}
                  <Input
                    name="quantity"
                    register={register}
                    type="number"
                    label={getLabel("protocolQuantity")}
                    placeholder={getLabel("protocolQuantityPlaceholder")}
                    error={errors["quantity"]}
                    regExpPattern={{
                      value: INT_FLOAT_REGEX,
                      message: getLabel("errorIntFloat"),
                    }}
                    min={{
                      value: getValues("product")?.min_range?.toString() || 0,
                      message: getLabel("errorMinValue", {
                        value: getValues("product")?.min_range?.toString(),
                      }),
                    }}
                    max={{
                      value:
                        getValues("product")?.max_range?.toString() || null,
                      message: getLabel("errorMaxValue", {
                        value: getValues("product")?.max_range?.toString(),
                      }),
                    }}
                  />
                </>
              )}
          </div>
          <div className="operation_info_container_row">
            {getValues("protocolType")?.value &&
              "HOMOGENIZATION_REASSEMBLY" ===
                getValues("protocolType")?.value &&
              getValues("operation")?.value === "HOMOGENIZATION_MASS" && (
                <>
                  <Select
                    name="homogenizationType"
                    control={control}
                    label={getLabel("protocolHomogenizationType")}
                    placeholder={getLabel(
                      "protocolHomogenizationTypePlaceholder"
                    )}
                    errors={errors["homogenizationType"]}
                    required={getLabel("inputRequiredError")}
                    options={[
                      {
                        label: getLabel("protocolHomogenizationMass_CLOSED"),
                        value: "CLOSED",
                      },
                      {
                        label: getLabel("protocolHomogenizationMass_OPEN"),
                        value: "OPEN",
                      },
                    ]}
                  />
                  <Input
                    name="homogenizationWorkTime"
                    register={register}
                    type="number"
                    label={getLabel("protocolHomogenizationWorkTime")}
                    placeholder={getLabel(
                      "protocolHomogenizationWorkTimePlaceholder"
                    )}
                    error={errors["homogenizationWorkTime"]}
                    min={{
                      value: 1,
                      message: getLabel("errorMinValue", { value: 1 }),
                    }}
                    max={{
                      value: 24 * 60,
                      message: getLabel("errorMaxValue", { value: 24 * 60 }),
                    }}
                    regExpPattern={{
                      value: INT_REGEX,
                      message: getLabel("errorInt"),
                    }}
                  />
                  <Input
                    name="homogenizationPauseTime"
                    register={register}
                    type="number"
                    label={getLabel("protocolHomogenizationPauseTime")}
                    placeholder={getLabel(
                      "protocolHomogenizationPauseTimePlaceholder"
                    )}
                    error={errors["homogenizationPauseTime"]}
                    min={{
                      value: 1,
                      message: getLabel("errorMinValue", { value: 1 }),
                    }}
                    max={{
                      value: 24,
                      message: getLabel("errorMaxValue", { value: 24 }),
                    }}
                    regExpPattern={{
                      value: INT_REGEX,
                      message: getLabel("errorInt"),
                    }}
                  />
                  <Input
                    name="homogenizationMotorSpeed"
                    register={register}
                    type="number"
                    label={getLabel("protocolHomogenizationMotorSpeed")}
                    placeholder={getLabel(
                      "protocolHomogenizationMotorSpeedPlaceholder"
                    )}
                    error={errors["homogenizationMotorSpeed"]}
                    min={{
                      value: 1,
                      message: getLabel("errorMinValue", { value: 1 }),
                    }}
                    max={{
                      value: 100,
                      message: getLabel("errorMaxValue", { value: 100 }),
                    }}
                    regExpPattern={{
                      value: INT_REGEX,
                      message: getLabel("errorInt"),
                    }}
                  />
                  <Input
                    name="homogenizationIterPerDay"
                    register={register}
                    type="number"
                    label={getLabel("protocolHomogenizationIterPerDay")}
                    placeholder={getLabel(
                      "protocolHomogenizationIterPerDayPlaceholder"
                    )}
                    error={errors["homogenizationIterPerDay"]}
                    min={{
                      value: 1,
                      message: getLabel("errorMinValue", { value: 1 }),
                    }}
                    max={{
                      value: 20,
                      message: getLabel("errorMaxValue", { value: 20 }),
                    }}
                    regExpPattern={{
                      value: INT_REGEX,
                      message: getLabel("errorInt"),
                    }}
                  />
                </>
              )}
            {getValues("operation")?.value &&
              "OXYGENATION" === getValues("operation")?.value && (
                <>
                  <Select
                    name="oxigenationType"
                    control={control}
                    label={getLabel("protocolOxigenationTypology")}
                    placeholder={getLabel(
                      "protocolOxigenationTypologyPlaceholder"
                    )}
                    errors={errors["oxigenationType"]}
                    required={getLabel("inputRequiredError")}
                    options={[
                      {
                        label: getLabel("protocolOXYGENATION_MICRO"),
                        value: "MICRO",
                      },
                      {
                        label: getLabel("protocolOXYGENATION_MACRO"),
                        value: "MACRO",
                      },
                      {
                        label: getLabel("protocolOXYGENATION_CUSTOM"),
                        value: "CUSTOM",
                      },
                    ]}
                  />
                  {["MICRO", "MACRO"].includes(
                    getValues("oxigenationType")?.value
                  ) && (
                    <Input
                      name="oxygenationDose"
                      register={register}
                      type="number"
                      label={
                        "MICRO" === getValues("oxigenationType")?.value
                          ? getLabel("protocolOxygenationDose_MICRO")
                          : getLabel("protocolOxygenationDose_MACRO")
                      }
                      placeholder={getLabel(
                        "protocolOxygenationDosePlaceholder"
                      )}
                      error={errors["oxygenationDose"]}
                      min={{
                        value: 1,
                        message: getLabel("errorMinValue", { value: 1 }),
                      }}
                      max={{
                        value: 1000,
                        message: getLabel("errorMaxValue", { value: 1000 }),
                      }}
                      regExpPattern={{
                        value: INT_REGEX,
                        message: getLabel("errorInt"),
                      }}
                    />
                  )}
                  {"CUSTOM" === getValues("oxigenationType")?.value && (
                    <>
                      <Input
                        name="oxygenationDose"
                        register={register}
                        type="number"
                        label={getLabel("protocolOxygenationDose_CUSTOM")}
                        placeholder={getLabel(
                          "protocolOxygenationDosePlaceholder"
                        )}
                        error={errors["oxygenationDose"]}
                        min={{
                          value: 1,
                          message: getLabel("errorMinValue", { value: 1 }),
                        }}
                        max={{
                          value: 1000,
                          message: getLabel("errorMaxValue", { value: 1000 }),
                        }}
                        regExpPattern={{
                          value: INT_REGEX,
                          message: getLabel("errorInt"),
                        }}
                      />
                      <Input
                        name="oxygenationDoseHours"
                        register={register}
                        type="number"
                        label={getLabel("protocolOxygenationDoseHours")}
                        placeholder={getLabel(
                          "protocolOxygenationDoseHoursPlaceholder"
                        )}
                        error={errors["oxygenationDoseHours"]}
                        min={{
                          value: 1,
                          message: getLabel("errorMinValue", { value: 0 }),
                        }}
                        max={{
                          value: 1000,
                          message: getLabel("errorMaxValue", { value: 23 }),
                        }}
                        regExpPattern={{
                          value: INT_REGEX,
                          message: getLabel("errorInt"),
                        }}
                      />
                      <Input
                        name="oxygenationDoseMinutes"
                        register={register}
                        type="number"
                        label={getLabel("protocolOxygenationDoseMinutes")}
                        placeholder={getLabel(
                          "protocolOxygenationDoseMinutesPlaceholder"
                        )}
                        error={errors["oxygenationDoseMinutes"]}
                        min={{
                          value: 1,
                          message: getLabel("errorMinValue", { value: 0 }),
                        }}
                        max={{
                          value: 1000,
                          message: getLabel("errorMaxValue", { value: 59 }),
                        }}
                        regExpPattern={{
                          value: INT_REGEX,
                          message: getLabel("errorInt"),
                        }}
                      />
                    </>
                  )}
                  {/* <Input
                    name="oxygenationIterPerDay"
                    register={register}
                    type="number"
                    label={getLabel("protocolOxygenationIterPerDay")}
                    placeholder={getLabel(
                      "protocolOxygenationIterPerDayPlaceholder"
                    )}
                    error={errors["oxygenationIterPerDay"]}
                    min={{
                      value: 1,
                      message: getLabel("errorMinValue", { value: 1 }),
                    }}
                    max={{
                      value: 20,
                      message: getLabel("errorMaxValue", { value: 20 }),
                    }}
                    regExpPattern={{
                      value: INT_REGEX,
                      message: getLabel("errorInt"),
                    }}
                  /> */}
                </>
              )}
          </div>
          <div className="operation_info_container_row">
            <TextArea
              name="goal"
              register={register}
              label={getLabel("protocolGoals")}
              placeholder={getLabel("protocolGoalsPlaceholder")}
              error={errors["goal"]}
              maxLength={{
                value: 100,
                message: getLabel("errorMaxLength", { value: 100 }),
              }}
            />
            <TextArea
              name="note"
              register={register}
              label={getLabel("protocolNotes")}
              placeholder={getLabel("protocolNotesPlaceholder")}
              error={errors["note"]}
              maxLength={{
                value: 100,
                message: getLabel("errorMaxLength", { value: 100 }),
              }}
            />
          </div>
        </WineMakingProtocolRowModal>
      </div>
    );
  };

  return (
    <div className="primary_container">
      <SecondaryBar
        breadCrumb={[
          getLabel("protocolsNavLink"),
          getLabel(
            id ? "wineMakingProtocolsUpdatePage" : "wineMakingProtocolsNewPage",
            {
              type: "",
              name: "",
            }
          ),
        ]}
        isBasePathNeeded={false}
      />
      {renderNavMenu()}
      {renderForm()}
    </div>
  );
};

export default WineMakingProtocolForm;
