import { useDispatch, useSelector } from "react-redux"
import { selectActiveWinery, selectLotDisplayGrid, selectLots, selectTanks } from "../../context/selectors"
import useLabels from "../../hooks/useLabels"
import { useNavigate } from "react-router-dom";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { getLots, getTanks } from "../../services/utils";
import { setLots } from "../../context/lots/lotsSlice";
import { setTanks } from "../../context/tanks/tanksSlice";
import { useEffect, useState } from "react";
import LotTile from "./LotTile";
import { toggleLotsDisplayGrid } from "../../context/configs/configsSlice";
import { Switch } from "../FormComponents";

import './styles/index.scss'
import TableEdit from "../TableEdit";
import { createColumnHelper } from "@tanstack/react-table";
import TableCell from "../TableEdit/TableCell";
import MultipleRowsCell from "../TableEdit/MultipleRowsCell";


const LotList = ({
    isMobile,
}) => {
    const activeWinery = useSelector(selectActiveWinery)
    const lots = useSelector(selectLots);
    const displayModeGrid = useSelector(selectLotDisplayGrid);
    const dispatch = useDispatch();
    const [getLabel] = useLabels();
    const navigate = useNavigate();
    const axiosPrivate = useAxiosPrivate();
    const tanks = useSelector(selectTanks);
    const [tankList, setTankList ] = useState({});
    
    const columnHelper = createColumnHelper();
    const columns = [
        columnHelper.accessor("name", {
            header: getLabel("lotName"),
            cell: TableCell,
            meta: {
                filterVariant: 'text',
            }
        }),
        columnHelper.accessor((row) => row?.origins?.toString() + row?.years?.map((y) => String(y))?.toString() + row?.varieties?.toString(), {
            header: getLabel("lotOrigins"),
            cell: MultipleRowsCell,
            meta: {
                array: "aggregate",
                selector: "aggregate",
                filterVariant: 'text',
            },
            filterFn: 'MultipleRowsFilterFn',
        }),
        columnHelper.accessor('lotTanks', {
            header: getLabel("lotTanks"),
            cell: MultipleRowsCell,
            meta: {
                array: "tanks",
                selector: "tank_name",
                filterVariant: 'text',
            },
            filterFn: 'MultipleRowsFilterFn',
        }),
        // columnHelper.accessor('varieties', {
        //     header: getLabel("lotVarieties"),
        //     cell: MultipleRowsCell,
        //     meta: {
        //         array: "varieties",
        //         selector: "varieties",
        //     },
        // }),
        // columnHelper.accessor('origins', {
        //     header: getLabel("lotOrigins"),
        //     cell: MultipleRowsCell,
        //     meta: {
        //         array: "origins",
        //         selector: "origins",
        //     },
        // }),
        // columnHelper.accessor('years', {
        //     header: getLabel("lotYears"),
        //     cell: MultipleRowsCell,
        //     meta: {
        //         array: "years",
        //         selector: "years",
        //     },
        // }),
        columnHelper.accessor((row) => getLabel(`lotState_${row.state}`) + (row?.state === 'WINE' && row?.type ? ` (${getLabel(`lotType_${row?.type}`)?.toLowerCase()})` : ''), {
            header: getLabel("lotState"),
            cell: TableCell,
            meta: {
                filterVariant: 'select',
                iconType: 'state',
            },
        }),
        columnHelper.accessor((row) => `${row?.actual_quantity} ${row?.state === 'GRAPE' ? 'Kg' : 'L'}`, {
            // (row) => `${row.state !== 'GRAPE' ? row.quantity : row.actual_quantity} L`, {
            header: getLabel("lotQuantity"),
            cell: TableCell,
            filterFn: 'QuantityInRange',
            meta: {
                filterVariant: 'range',
                selector: 'actual_quantity'
            }
        }),
        columnHelper.accessor((row) => (row?.color ? getLabel(`tankColor${row.color}`) : '-'), {
            header: getLabel("lotColor"),
            cell: TableCell,
            meta: {
                filterVariant: 'select',
                iconType: 'fa-droplet',
            },
        }),
    ];

    const toggleDisplayMode = () => {
        dispatch(toggleLotsDisplayGrid())
    }

    const loadLots = async () => {
        const currentLots = await getLots(activeWinery.id, axiosPrivate);
        dispatch(setLots(currentLots))
    }

    useEffect(() => {
        if (!lots || lots.length === 0) {
            loadLots()
        }

        if (!tanks || tanks.length === 0) {
            loadTanks()
        }
    }, []);

    useEffect(() => {
        const filteredTanks = {};
        lots?.forEach((lot) => {
            const filteredSet = !lot?.tanks ? [] : lot?.tanks?.map((tank) =>
                tanks.find((t) => t.id === tank.tank_id)
            );
            if (filteredSet.length > 0)
                filteredTanks[lot?.id] = filteredSet;

        })
        setTankList(filteredTanks);
    }, [tanks, lots]);

    const loadTanks = async () => {
        const currentTanks = await getTanks(activeWinery.id, axiosPrivate);
        dispatch(setTanks(currentTanks))
    }

    const renderList = () => {
        if (lots?.length > 0) {
            const sortedLots = [...lots]?.sort((a, b) => (a?.name).localeCompare(b?.name, undefined, {numeric: true}));            
            return (
                <div className="lots_list_table">
                    <TableEdit
                        defaultData={sortedLots}
                        columns={columns}
                        rowClickHandler={
                            (row) => {
                                navigate(`/lots/${row.original.id}`);
                            }
                        }
                        filterType={"lots"}
                    />
                </div>
            )
        }
    }

    const renderGrid = () => {
        if (lots?.length > 0) {
            const sortedLots = [...lots]?.sort((a, b) => (a?.name).localeCompare(b?.name, undefined, {numeric: true}));            
            // return (
            //     <div className="lots_grid">
            //         {sortedLots?.map((lot, i) => {
            //             return <LotTile {...lot} tanks={tankList[lot.id]} key={`lot_tile_${i}`} />
            //         })}
            //     </div>
            // )
            return (
                <div className="lots_list_table">
                    <TableEdit
                        type={'lot'}
                        isMobile={isMobile || displayModeGrid}
                        defaultData={sortedLots}
                        columns={columns}
                        rowClickHandler={
                            (row) => {
                                navigate(`/lots/${row.original.id}`);
                            }
                        }
                        filterType={"lots"}
                    />
                </div>
            )
        }
    }
    
    return (
        <div className="lots_list">
            {lots?.length > 0 && <>{!isMobile && <Switch
                leftOption={{
                    label: getLabel('listView')
                }}
                rightOption={{
                    label: getLabel('gridView')
                }}
                toggleHandler={toggleDisplayMode}
                        status={displayModeGrid}
            />}
            {isMobile ? renderGrid() : displayModeGrid ? renderGrid() : renderList()}
            </>}
            {lots?.length === 0 && 
        <div className="protocols_alert">
        <h6 className="protocols_alert_title">
          {getLabel("noLotsAlert")}
        </h6>
      </div>}
        </div>
    )
}

export default LotList