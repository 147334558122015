import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import useLabels from "../../hooks/useLabels";
import { deleteProtocol, getProtocols } from "../../services/utils";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { selectAnalysis, selectTreatments } from "../../context/selectors";
import {
  setTreatments,
  removeTreatments,
} from "../../context/protocols/treatmentsSlice";
import {
  setAnalysis,
  removeAnalysis,
} from "../../context/protocols/analysisSlice";
import SecondaryBar from "../SecondaryBar";
import { Button, ConfirmationModal, CTA } from "../Generic";
import { Label } from "../FormComponents";

import "./styles/index.scss";
import { toast } from "react-toastify";
import TableEdit from "../TableEdit";
import TableCell from "../TableEdit/TableCell";
import { createColumnHelper } from "@tanstack/react-table";
import { ROLES } from "../../constants/base";
import useAuth from "../../hooks/useAuth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getItem } from "../../services/SessionStorage";
import * as XLSX from "xlsx";

const ProtocolDetail = () => {
  const { id, type } = useParams();
  const protocols = useSelector(
    type === "treatment" ? selectTreatments : selectAnalysis
  );

  const [getLabel] = useLabels();
  const dispatch = useDispatch();
  const axiosPrivate = useAxiosPrivate();
  const [protocol, setProtocol] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const { auth } = useAuth();
  const role = auth?.role || ROLES.GUEST;
  const navigate = useNavigate();

  const pageName = getLabel("protocolTitlePage", {
    name: protocol?.protocols_name || id,
    type: getLabel(type) || "",
  });

  const columnHelper = createColumnHelper();
  
  const drugTypeColumns =
    type === "treatment"
      ? [
          columnHelper.accessor(
            (row) => (row?.type ? getLabel(`drugType_${row.type?.toUpperCase()}`) : ""),
            {
              header: getLabel("drugType"),
              cell: TableCell,
              meta: {
                type: "text",
              },
            }
          ),
          columnHelper.accessor("quantity", {
            header: getLabel("drugQuantity"),
            cell: TableCell,
            meta: {
              type: "number",
              editable: true,
            },
          }),
        ]
      : [];
  
  const columns = [
    columnHelper.accessor((row) => type === "treatment" ? row?.substance_name : getLabel("substance_" + row?.substance_name), {
      header: type === "treatment" ? getLabel("drugName") : getLabel("analysisName"),
      cell: TableCell,
      meta: {
        type: "text",
      },
    }),
    ...drugTypeColumns,
    columnHelper.accessor("unit", {
      header: getLabel("unit"),
      cell: TableCell,
      meta: {
        type: "number",
      },
    }),
    columnHelper.accessor(
      (row) => `${row?.min_range ? String(row?.min_range) : 'n/a'} - ${row?.max_range ? String(row?.max_range) : 'n/a'} ${row?.unit}`,
      {
        header: getLabel("substanceRange"),
        cell: TableCell,
        meta: {
          type: "number",
        },
      }
    ),
    // columnHelper.accessor(
    //   (row) => parseInt(row?.min_range) >= 0 && parseInt(row?.max_range) >= 0 ? `${String(row?.min_range) || '0'} - ${String(row?.max_range) || ''} ${row?.unit}` : '',
    //   {
    //     header: getLabel("substanceRange"),
    //     cell: TableCell,
    //     meta: {
    //       type: "number",
    //     },
    //   }
    // ),
    columnHelper.accessor(
      (row) => parseInt(row?.min_temperature) && parseInt(row?.max_temperature) ? `${row?.min_temperature || ''} - ${row?.max_temperature || ''} \u00b0C` : '',
      {
        header: getLabel("substanceTemperature"),
        cell: TableCell,
        meta: {
          type: "number",
        },
      }
    ),
    // columnHelper.accessor(
    //   (row) => row?.min_range >= 0 ? `${row?.min_range} ${row?.unit}` : "",
    //   {
    //     header: getLabel("drugMinRange"),
    //     cell: TableCell,
    //     meta: {
    //       type: "number",
    //     },
    //   }
    // ),
    // columnHelper.accessor(
    //   (row) => row?.max_range >= 0 ? `${row?.max_range} ${row?.unit}` : "",
    //   {
    //     header: getLabel("drugMaxRange"),
    //     cell: TableCell,
    //     meta: {
    //       type: "number",
    //     },
    //   }
    // ),
    // columnHelper.accessor(
    //   (row) =>
    //     (row?.min_temperature || "") +
    //     (row?.min_temperature ? " " + "\u00b0 C" : ""),
    //   {
    //     header: getLabel("drugMinTemp"),
    //     cell: TableCell,
    //     meta: {
    //       type: "number",
    //     },
    //   }
    // ),
    // columnHelper.accessor(
    //   (row) =>
    //     (row?.max_temperature || "") +
    //     (row?.max_temperature ? " " + "\u00b0 C" : ""),
    //   {
    //     header: getLabel("drugMaxTemp"),
    //     cell: TableCell,
    //     meta: {
    //       type: "date",
    //     },
    //   }
    // ),
  ];

  const handleOpenModal = () => {
    setIsOpen(true);
  };

  const handleCloseModal = () => {
    setIsOpen(false);
  };

  const handleFormSubmit = async () => {
    await removeProtocol();
    handleCloseModal();
  };

  useEffect(() => {
    if (!protocols || protocols.length === 0) {
      loadProtocols();
    }
  }, []);

  useEffect(() => {
    if (protocols && protocols.length > 0) {
      setProtocol(
        protocols.find((dbProtocol) => {
          return Number(dbProtocol.protocols_id) === Number(id);
        }) || {}
      );
    }
  }, [protocols, id]);

  const loadProtocols = async () => {
    const currentProtocols = await getProtocols(type, axiosPrivate);
    dispatch(
      type === "treatment"
        ? setTreatments(currentProtocols)
        : setAnalysis(currentProtocols)
    );
  };

  const removeProtocol = async () => {
    const toastId = toast.loading(getLabel("toast_inProgress"), {
      type: toast.TYPE.INFO,
      position: toast.POSITION.BOTTOM_RIGHT,
      exclude: true,
    });

    const response = await deleteProtocol(id, type, axiosPrivate);
    const requestPayload = {
      ...(id && { id: Number(id) }),
    };

    toast.update(toastId, {
      render: response?.success
        ? getLabel(response?.success, { name: protocol?.name || "" })
        : getLabel(response?.error),
      type: response?.error ? toast.TYPE.ERROR : toast.TYPE.SUCCESS,
      isLoading: false,
      position: toast.POSITION.BOTTOM_RIGHT,
      autoClose: 4000,
    });

    if (response && response?.success) {
      dispatch(
        type === "treatment"
          ? removeTreatments(requestPayload)
          : removeAnalysis(requestPayload)
      );
      navigate(`/protocols/${type}`);
    }
  };

  const buildJson = () => {
    const data = [];
    const array = protocol?.[type === 'treatment' ? 'drugs' : 'analysis'] || [];
    array?.forEach((row) => {
      const tmp = [
        type === 'treatment' ? row?.substance_name : getLabel(`substance_${row?.substance_name}`),
        row?.unit,
      ];
      tmp.push(row?.min_range ? String(row?.min_range) : '')
      tmp.push(row?.max_range ? String(row?.max_range) : '')
      if (type === 'treatment') {
        tmp.splice(1, 0, row?.quantity);
        tmp.push(getLabel(`drugType_${row?.type}`)?.toUpperCase());
        tmp.push(row?.min_temperature ? String(row?.min_temperature) : '')
        tmp.push(row?.max_temperature ? String(row?.max_temperature) : '')
      }
      data.push(tmp);
    });
    
    return data;
  };

  const handleExport = () => {
    const toastId = toast.loading(getLabel("toast_inProgress"), {
      type: toast.TYPE.INFO,
      position: toast.POSITION.BOTTOM_RIGHT,
      exclude: true,
      isLoading: true,
    });

    const data = buildJson();
    
    const language = getItem("i18LanguageLocale") || "it";
    const headers = [];
    switch (language) {
      case "en":
        headers.push(
          ...[
            type === 'treatment' ? "PRODUCT NAME" : "ANALYSIS NAME",
            "UNIT",
          ]
        );
        break;
      default:
        headers.push(
          ...[
            type === 'treatment' ? "NOME PRODOTTO" : "NOME ANALISI",
            "UNITA'"
          ]
        );
    }
    
    headers.push(getLabel("substanceMinRang")?.toUpperCase(), getLabel("substanceMaxRang")?.toUpperCase());

    if (type === 'treatment') {
      headers.splice(1, 0, getLabel('drugQuantity'));
      headers.push(getLabel("drugType"));
      headers.push(...[getLabel("drugMinTemp")?.toUpperCase(), getLabel("drugMaxTemp")?.toUpperCase()]);
    }

    toast.update(toastId, {
      render:
        data?.length === 0
          ? getLabel("toast_exportProtocolError")
          : getLabel("toast_exportProtocolSuccess"),
      type: data?.length === 0 ? toast.TYPE.ERROR : toast.TYPE.SUCCESS,
      isLoading: false,
      position: toast.POSITION.BOTTOM_RIGHT,
      autoClose: 4000,
    });
    if (data?.length === 0) return;

    const worksheet = XLSX.utils.json_to_sheet(data);
    // var worksheet = XLSX.utils.aoa_to_sheet([
    //   [ '' ], // A1
    //   [ {t: "n", f: "A2+A3"}, {t: "s", f: 'CHOOSE("","red","blue","green")'} ] // A2
    // ]);

    const wscols = [
      { wch: 15 },
      { wch: 10 },
    ];
    const analysisFileName = '';
    if (type === 'analysis') { 
      wscols[0] = {wch: 18}
      wscols.push(...[{ wch: 18 }, { wch: 18 }]);
      analysisFileName = `__[${protocol?.state}-${protocol?.color}]`;
    } else if (type === 'treatment') {
      wscols.push(...[{ wch: 10 }, { wch: 18 }, { wch: 18 }, { wch: 11 }, { wch: 18 }, { wch: 18 }]);
    }
    worksheet["!cols"] = wscols;
    XLSX.utils.sheet_add_aoa(worksheet, [headers], { origin: "A1" });
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    const protocolName = protocol?.protocols_name || 'data';
    XLSX.writeFile(
      workbook,
      `${protocolName}${analysisFileName}.xlsx`,
      { compression: true }
    );
  };

  const renderNavMenu = () => {
    const navClickHandler = () => {
      navigate(`/protocols/${type}`);
    };
    return (
      <div className="primary_container_menu">
        <div className="primary_container_row">
          <Button arrowDirection="left" onClick={navClickHandler} />
          <h2>{pageName}</h2>
        </div>
        {role === ROLES.CLIENT && <div className="primary_container_row">
          <NavLink to={`/protocols/${type}/${id}/update`}>
            <CTA>{getLabel("updateProtocol")}</CTA>
          </NavLink>

          <CTA onClick={handleOpenModal}>{getLabel("deleteProtocol")}</CTA>
          <CTA onClick={handleExport}>
            {getLabel("operationExcelExport")}{" "}
            <FontAwesomeIcon icon="fa-file-arrow-down" />
          </CTA>
            
          <ConfirmationModal
            isOpen={isOpen}
            onConfirm={handleFormSubmit}
            onClose={handleCloseModal}
            description={getLabel("modalDeleteProtocolDescription", {
              name: protocol?.protocols_name || "",
            })}
          ></ConfirmationModal>
        </div>}
      </div>
    );
  };

  const renderNextOperation = () => {
    return (
      <div>
        <div className="primary_container_menu">
          <h4>{getLabel("protocol_nextOperations")}</h4>
          <NavLink to={"/programming/new"}>
            <CTA>{getLabel("programOperation")}</CTA>
          </NavLink>
        </div>
      </div>
    );
  };

  const getRightQuantity = (substance) => {
    const index = protocol?.substance_ids.indexOf(substance.substance_id);
    const protocolQuantity =
      protocol?.quantities?.length > index ? protocol?.quantities[index] : 0;

    return (protocolQuantity > 0 ? protocolQuantity : substance?.quantity) || 0;
  };

  return (
    <div className="primary_container">
      <SecondaryBar
        breadCrumb={[
          getLabel("protocolsNavLink"),
          getLabel("protocolTitlePage", {
            name: "",
            type: "",
          }),
        ]}
        isBasePathNeeded={false}
      />
      {renderNavMenu()}
      {type === "analysis" && <div className="protocol_info_container_row">
        <Label label={getLabel("analysisState")}>
          {protocol?.state ? getLabel(`lotState_${protocol?.state}`) : ""}
        </Label>
        <Label label={getLabel("analysisColor")}>
          {protocol?.color ? getLabel(`lotColor_${protocol?.color}`) : ""}
        </Label>
      </div>}
      <h4 className="">
        {type === "treatment"
          ? getLabel("treatmentProtocolSubstances")
          : getLabel("analysisProtocolSubstances")}
      </h4>
      <TableEdit
        defaultData={
          (type === "treatment" ? protocol?.drugs : protocol?.analysis)?.map(
            (s, i) => {
              return {
                ...s,
                quantity: getRightQuantity(s),
              };
            }
          ) || []
        }
        columns={columns}
        isSearchBar={false}
      />
      {type === "substance" && <><div className="protocol_info_container_row">
        <Label label={getLabel("drugIdealQuantity")}>
          {protocol?.quantity ? protocol?.quantity : ""}
        </Label>
        <Label label={getLabel("drugMinRange")}>
          {protocol?.min_range ? protocol?.min_range : ""}
        </Label>
        <Label label={getLabel("drugMaxRange")}>
          {protocol?.max_range ? protocol?.max_range : ""}
        </Label>
      </div>
      <div className="protocol_info_container_row">
        <Label width={400} height={150} label={getLabel("protocolNotes")} customClassName={"notes"}>
          {protocol.notes}
        </Label>
      </div></>}

      {renderNextOperation()}
    </div>
  );
};

export default ProtocolDetail;
