import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState, useEffect } from "react";
import { INT_FLOAT_REGEX } from "../../constants/regex";
import useLabels from "../../hooks/useLabels";
import { Input, Switch } from "../FormComponents";
import { Tooltip, CTA } from "../Generic";
import "./styles/index.scss";

const TableCell = ({ getValue, row, column, table }) => {
  const [getLabel] = useLabels();
  const initialValue = getValue();

  const columnMeta = column.columnDef.meta;
  const tableMeta = table.options.meta;
  const [value, setValue] = useState(initialValue);
  const [id, setId] = useState(row.original.id || row.original.substance_id || row.original.value);
  const errors = tableMeta.getErrors();

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const onBlur = () => {
    tableMeta?.updateData(row.index, column.id, value);
  };

  const onSelectChange = (e) => {
    setValue(e.target.value);
    tableMeta?.updateData(row.index, column.id, e.target.value);
  };

  const getError = () => {
    if (!errors || !errors?.length === 0 || Object.keys(errors)?.length === 0)
      return;
    return errors?.column?.[id]?.value;
  };

  const renderExactDay = () => {
  }

  const getWineColor = (color) => {
    switch (color) {
      case "WHITE":
        return "rgb(249, 213, 115)";
      case "ROSE":
        return "rgb(199, 79, 89)";
      default:
        return "#64242e";
    }
  };
  
  const getState = (state) => {
    switch (state) {
      case "GRAPE":
        return "fa-cubes-stacked";
      case "MUST":
        return "fa-cubes-stacked";
      case "WINE":
        return "fa-droplet";
      case "BOTTLED":
        return "fa-wine-bottle";
      default:
        return "fa-wine-bottle";
    }
  };

  const getTankType = (type) => {
    switch (type) {
      case "TANK":
        return "fa-cubes-stacked";
      case "AUTOCLAVE":
        return "fa-cubes-stacked";
      case "TONNEAU":
        return "fa-droplet";
      case "BARRIQUE":
        return "fa-wine-bottle";
      default:
        return "#64242e";
    }
  };
  
  const getIcon = (iconType) => {
    const icon = { icon: iconType, style: {} };
    switch(iconType) {
      case 'fa-droplet': 
        if (row?.original?.color) icon.style.color = getWineColor(row?.original?.color); 
        break;
  
      case 'state':
      case 'stateWithColor':
      //   if (row?.original?.state) icon.icon = getState(row.original.state); 
      //   if (row?.original?.state === 'BOTTLED') icon.style.transform = 'rotate(-45deg)';
      //   if (iconType === 'stateWithColor' && row.original?.color) icon.style.color = getWineColor(row.original.color);
        icon.icon = null;
        break;
      
      // case 'tankType':
      //   if (row.original?.type) icon.icon = getTankType(row.original?.type);
      //   break;
    }

    return icon;
  }

  const renderIcon = () => {
    if (columnMeta?.iconType) {
      const icon = getIcon(columnMeta.iconType);
      if (icon?.icon)
        return <span>{" "}<FontAwesomeIcon icon={icon.icon} style={icon.style} size="xs" /></span>
    }
  }

  const selectRow = () => {
    setValue((prev) => !prev);
    tableMeta?.selectRow(row.index, !value);
  };
  
  if (columnMeta?.type === 'switch') {
    return <Switch
      leftOption={{
          label: getLabel('listView')
      }}
      rightOption={{
          label: getLabel('gridView')
      }}
      // toggleHandler={toggleDisplayMode}
      // status={displayModeGrid}
    />
  } else if (columnMeta?.type === 'checkbox') {
    return <input
      type="checkbox"
      checked={value}
      // checked={row.getIsSelected()}
      // onChange={row.getToggleSelectedHandler()}
      onChange={() => selectRow()}
    />
  } else if (columnMeta?.type === 'email_validity') {
    return (<>
      <span>{value ? getLabel('userEmailVerified') : getLabel('userEmailUnverified')}</span>
      {!value && <span style={{marginLeft: '10px'}}>
      <CTA onClick={e => {console.log('Resend e-mail...'); e.stopPropagation();}}>
        {getLabel('userEmailResendInvite')}
      </CTA>
      </span>}
    </>
    )
  } else if (columnMeta?.type === 'remove_cookie') {
    return (<>
      {value === true && 
      <CTA onClick={columnMeta?.onClickHandler}>
        {getLabel('cookie_settings_mandatory_viewed_cookie_policy_deny')}
      </CTA>}
      {!value && <span>{getLabel("cookie_settings_mandatory_no_deny")}</span>}
    </>
    )
  } else if (
    (tableMeta?.editedRows[row.id] && columnMeta?.editable) ||
    (tableMeta?.analysisState === "INSERTING" &&
      Number(row.id) >= tableMeta?.minEditableIndex &&
      Number(row.id) < tableMeta?.maxEditableIndex &&
      columnMeta?.editable)
  ) {
    return columnMeta?.type === "select" ? (
      <select onChange={onSelectChange} value={initialValue || ""}>
        {columnMeta?.options?.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    ) : (
      <Input
        onChange={(e) => {
          tableMeta.setValue(`column.${id}.old`, e.target.defaultValue);
          tableMeta.setValue(`column.${id}.substance_id`, row.original?.value || row.original?.substance_id);
          setValue(columnMeta?.noStoreValue ? '' : (e.target.value || row.original?.quantity))
        }}
        onBlur={onBlur}
        name={`column.${id}.value`}
        register={columnMeta?.register}
        isLabel={false}
        type={columnMeta?.type || "text"}
        required={getLabel("inputRequiredError")}
        customClassName={"quantity"}
        placeholder={
          row.original?.opType === "ANALYSIS"
            ? getLabel("examResultPlaceholder")
            : getLabel("drugQuantityPlaceholder")
        }
        // defaultValue={value?.split(' ')?.[0]}
        defaultValue={value}
        error={getError()}
        regExpPattern={{
          value: INT_FLOAT_REGEX,
          message: getLabel("errorIntFloat"),
        }}
        min={{
          value: row?.original?.min_range?.toString() || 0.01,
          message: getLabel("errorDrugQuantityUnderMin"),
        }}
        max={{
          value: row?.original?.max_range?.toString() || null,
          message: getLabel("errorDrugQuantityOverMax"),
        }}
      />
    );
  }
  return (column?.id?.startsWith('note') || column?.id === 'goal') ? (
    <Tooltip
      variant={"info"}
      html={value}
      place="left"
      events={["hover"]}
    >
      <span className={'note'}>
        {value}
      </span>
    </Tooltip>
  ) : (
  <span
    className={`${column?.id === getLabel("examResult") ? "quantity" : ''} ${columnMeta?.customClassName === 'hide' && !row.original?.isTankFirst ? 'hide' : ''}`}
  >
    {renderIcon()}{" "}{value}{renderExactDay()}
  </span>);
};

export default TableCell;
