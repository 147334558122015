import { useNavigate, useParams } from "react-router-dom";
import useLabels from "../../hooks/useLabels";
import { Button } from "../Generic";
import SecondaryBar from "../SecondaryBar";
import { useForm, useWatch } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { selectDrugs, selectUnits } from "../../context/selectors";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useEffect, useState } from "react";
import { getUnits, getDrugs, getExams } from "../../services/utils";
import { Input, Select, Submit, TextArea } from "../FormComponents";
import {
  addDrugs,
  setDrugs,
  updateDrugs,
} from "../../context/substances/drugsSlice";
import { setExams } from "../../context/substances/examsSlice";
import { setUnits } from "../../context/units/unitsSlice";
import { newDrug, updateDrug } from "../../services/utils";
import {
  ALPHA_NUM_SPACE_DASH_UNDERSCORE_REGEX,
  INT_FLOAT_REGEX,
} from "../../constants/regex";
import { toast } from "react-toastify";
import "./styles/index.scss";


const SubstanceForm = ({}) => {
  const { id, type } = useParams();
  const [getLabel] = useLabels();
  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();
  const dispatch = useDispatch();
  const substances = useSelector(selectDrugs);
  const units = useSelector(selectUnits);
  const [substance, setSubstance] = useState(
    substances?.length > 0
      ? substances.find((dbSubstance) => dbSubstance.id === Number(id)) || {}
      : {}
  );
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    reset,
    setValue,
    getValues,
    control,
  } = useForm({
    defaultValues: {
      name: substance?.name || "",
      quantity: substance?.quantity || 0,
      type: substance?.type
        ? {
            label: getLabel("drugType_" + substance.type?.toUpperCase()),
            value: substance.type,
          }
        : { label: "", value: "" },
      notes: substance?.notes || "",
      unit_id:
        substance?.unit_id && substance?.unit
          ? { label: substance.unit, value: substance.unit_id }
          : { label: "", value: 0 },
    },
    mode: "onTouched",
  });

  const { quantity, min_range, max_range, min_temperature, max_temperature } =
    useWatch({ control });

  useEffect(() => {
    if (id && (!substances || substances.length === 0)) {
      loadSubstances();
    }
  }, []);

  useEffect(() => {
    if (!units || units.length === 0) {
      loadUnits();
    }
  }, []);

  useEffect(() => {
    if (id && substances?.length > 0) {
      const singleSubstance =
        substances.find(
          (dbSubstance) => Number(dbSubstance.id) === Number(id)
        ) || null;
      if (singleSubstance) {
        setSubstance(singleSubstance);
        setValue("name", singleSubstance?.name || "");
        setValue("unit_id", {
          label: singleSubstance?.unit,
          value: singleSubstance?.unit_id,
        });
        setValue("quantity", singleSubstance?.quantity || 0);
        setValue("type", {
          label: getLabel(`drugType_${singleSubstance?.type?.toUpperCase()}`),
          value: singleSubstance?.type,
        });
        setValue("min_range", singleSubstance?.min_range || "");
        setValue("max_range", singleSubstance?.max_range || "");
        setValue("min_temperature", singleSubstance?.min_temperature || "");
        setValue("max_temperature", singleSubstance?.max_temperature || "");
        setValue("notes", singleSubstance?.notes || "");
      }
    }
  }, [substances, type, substance]);

  const loadSubstances = async () => {
    const currentSubstances =
      type === "treatment"
        ? await getDrugs(axiosPrivate)
        : await getExams(axiosPrivate);
    dispatch(
      type === "treatment"
        ? setDrugs(currentSubstances)
        : setExams(currentSubstances)
    );
  };

  const loadUnits = async () => {
    const currentUnits = await getUnits(axiosPrivate);
    dispatch(setUnits(currentUnits));
  };

  const submitForm = async (data) => {
    const requestPayload = {
      ...data,
      ...((data?.unit_id && {
        unit_id: data?.unit_id.value,
        unit: data?.unit_id.label,
      }) ||
        0),
      ...((data?.type && { type: data?.type.value }) || ""),
      ...(id && { id: Number(id) }),
    };

    const toastId = toast.loading(getLabel("toast_inProgress"), {
      type: toast.TYPE.INFO,
      position: toast.POSITION.BOTTOM_RIGHT,
      exclude: true,
    });
    let response = null;
    if (id) {
      response = await updateDrug(id, requestPayload, axiosPrivate);
    } else {
      response = await newDrug(requestPayload, axiosPrivate);
    }

    console.log(response);
    toast.update(toastId, {
      render: response?.success
        ? getLabel(response?.success, { name: data?.name || "" })
        : getLabel(response?.error),
      type: response?.error ? toast.TYPE.ERROR : toast.TYPE.SUCCESS,
      isLoading: false,
      position: toast.POSITION.BOTTOM_RIGHT,
      autoClose: 4000,
    });
    if (response && response?.success) {
      updateStore(requestPayload);
      navClickHandler();
    }
  };

  const updateStore = (payload) => {
    if (payload.id) {
      dispatch(updateDrugs(payload));
    } else {
      dispatch(addDrugs(payload));
    }
  };

  const navClickHandler = () => {
    if (id) {
      navigate(`/substances/${type}/${id}`);
    } else {
      navigate(`/protocols/${type}`);
    }
  };

  const renderNavMenu = () => {
    const navClickHandler = () => {
      if (id) {
        navigate(`/substances/${type}/${id}`);
      } else {
        navigate(`/protocols/${type}`);
      }
    };
    return (
      <div className="primary_container_menu">
        <div className="primary_container_row">
          <Button arrowDirection="left" onClick={navClickHandler} />
          <h2>
            {getLabel(id ? "drugUpdatePage" : "drugNewPage", {
              type: getLabel(type) || "",
            })}
          </h2>
        </div>
      </div>
    );
  };

  const renderForm = () => {
    return (
      <div className="substance_form_wrapper">
        <form onSubmit={handleSubmit(submitForm)} autoComplete="off" noValidate>
          <div className="substance_form_wrapper_row">
            <Input
              name="name"
              register={register}
              type="text"
              label={getLabel("drugName")}
              placeholder={getLabel("drugNamePlaceholder")}
              error={errors["name"]}
              required={getLabel("inputRequiredError")}
              minLength={{
                value: 3,
                message: getLabel("errorMinLength", { value: 3 }),
              }}
              maxLength={{
                value: 24,
                message: getLabel("errorMaxLength", { value: 24 }),
              }}
              regExpPattern={{
                value: ALPHA_NUM_SPACE_DASH_UNDERSCORE_REGEX,
                message: getLabel("errorAlphaNumSpace_-"),
              }}
            />
          </div>
          <div className="substance_form_wrapper_row">
            <Input
              name="min_range"
              register={register}
              type="number"
              label={getLabel("drugMinRange")}
              placeholder={getLabel("drugMinRangePlaceholder")}
              error={errors["min_range"]}
              minLength={{
                value: 1,
                message: getLabel("errorMinLength", { value: 1 }),
              }}
              maxLength={{
                value: 7,
                message: getLabel("errorMaxLength", { value: 7 }),
              }}
              regExpPattern={{
                value: INT_FLOAT_REGEX,
                message: getLabel("errorIntFloat"),
              }}
              max={{
                value: getValues("max_range")?.toString() || null,
                message: getLabel("errorDrugQuantityOverMax"),
              }}
            />
            <Input
              name="max_range"
              register={register}
              type="number"
              label={getLabel("drugMaxRange")}
              placeholder={getLabel("drugMaxRangePlaceholder")}
              error={errors["max_range"]}
              minLength={{
                value: 1,
                message: getLabel("errorMinLength", { value: 1 }),
              }}
              maxLength={{
                value: 7,
                message: getLabel("errorMaxLength", { value: 7 }),
              }}
              regExpPattern={{
                value: INT_FLOAT_REGEX,
                message: getLabel("errorIntFloat"),
              }}
              min={{
                value: getValues("min_range")?.toString() || -1,
                message: getLabel("errorDrugQuantityUnderMin"),
              }}
            />
            <Input
              name="quantity"
              register={register}
              type="number"
              label={getLabel("drugIdealQuantity")}
              placeholder={getLabel("drugIdealQuantityPlaceholder")}
              error={errors["quantity"]}
              minLength={{
                value: 1,
                message: getLabel("errorMinLength", { value: 1 }),
              }}
              maxLength={{
                value: 7,
                message: getLabel("errorMaxLength", { value: 7 }),
              }}
              regExpPattern={{
                value: INT_FLOAT_REGEX,
                message: getLabel("errorIntFloat"),
              }}
              min={{
                value: getValues("min_range")?.toString() || -1,
                message: getLabel("errorDrugQuantityUnderMin"),
              }}
              max={{
                value: getValues("max_range")?.toString() || null,
                message: getLabel("errorDrugQuantityOverMax"),
              }}
            />
            </div>
            <div className="substance_form_wrapper_row">
            <Input
              name="min_temperature"
              register={register}
              type="number"
              label={getLabel("drugMinTemperature")}
              placeholder={getLabel("drugMaxTempPlaceholder")}
              error={errors["min_temperature"]}
              minLength={{
                value: 1,
                message: getLabel("errorMinLength", { value: 1 }),
              }}
              maxLength={{
                value: 7,
                message: getLabel("errorMaxLength", { value: 7 }),
              }}
              regExpPattern={{
                value: INT_FLOAT_REGEX,
                message: getLabel("errorIntFloat"),
              }}
              max={{
                value: getValues("max_temperature")?.toString() || null,
                message: getLabel("errorDrugTemperatureOverMax"),
              }}
            />
            <Input
              name="max_temperature"
              register={register}
              type="number"
              label={getLabel("drugMaxTemperature")}
              placeholder={getLabel("drugMaxTempPlaceholder")}
              error={errors["max_temperature"]}
              minLength={{
                value: 1,
                message: getLabel("errorMinLength", { value: 1 }),
              }}
              maxLength={{
                value: 7,
                message: getLabel("errorMaxLength", { value: 7 }),
              }}
              regExpPattern={{
                value: INT_FLOAT_REGEX,
                message: getLabel("errorIntFloat"),
              }}
              min={{
                value: getValues("min_temperature")?.toString() || -1,
                message: getLabel("errorDrugTemperatureUnderMin"),
              }}
            />
          </div>
          <div className="substance_form_wrapper_row">
            <Select
              name="unit_id"
              control={control}
              label={getLabel("unit")}
              placeholder={getLabel("unitPlaceholder")}
              error={errors["unit"]}
              required={getLabel("inputRequiredError")}
              options={[...units]?.sort(function(a, b) {
                var textA = a.label.toUpperCase();
                var textB = b.label.toUpperCase();
                return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
            })}
              defaultValue={{
                value: 0,
                label: "",
              }}
            />
            </div>
            <div className="substance_form_wrapper_row">
            <Select
              name="type"
              control={control}
              label={getLabel("drugType")}
              placeholder={getLabel("drugTypePlaceholder")}
              error={errors["type"]}
              required={getLabel("inputRequiredError")}
              options={[
                {
                  value: "",
                  label: "",
                },
                {
                  value: "additive",
                  label: getLabel("drugType_ADDITIVE"),
                },
                {
                  value: "adjuvant",
                  label: getLabel("drugType_ADJUVANT"),
                },
              ]}
            />
          </div>
          <div className="substance_form_wrapper_row">
            <TextArea
              name="notes"
              register={register}
              label={getLabel("drugNotes")}
              placeholder={getLabel("drugNotesPlaceholder")}
              error={errors["notes"]}
              maxLength={{
                value: 32,
                message: getLabel("errorMaxLength", { value: 32 }),
              }}
            />
          </div>
          <div className="substance_form_wrapper_row">
            <Submit label={getLabel("submitForm")} />
          </div>
        </form>
      </div>
    );
  };

  return (
    <div className="primary_container">
      <SecondaryBar
        breadCrumb={[
          getLabel("drugNavLink"),
          getLabel(id ? "drugUpdatePage" : "drugNewPage", { type: "" }),
        ]}
        isBasePathNeeded={false}
      />
      {renderNavMenu()}
      {renderForm()}
    </div>
  );
};

export default SubstanceForm;
