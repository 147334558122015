import { useDispatch, useSelector } from "react-redux"
import { selectActiveWinery, selectUsers, selectUser } from "../../context/selectors"
import useLabels from "../../hooks/useLabels"
import { useNavigate } from "react-router-dom";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { getUsers, updateSubtypePermissions } from "../../services/utils";
import { emptyUsers, setUsers } from "../../context/users/usersSlice";
import { useEffect, useMemo, useRef, useState } from "react";
import Table from "../Table";

import './styles/index.scss'
import { toast } from "react-toastify";

const PermissionColumn = ({
    data = [],
    dataKey,
    subtype,
    onChange,
    canModify = false,
}) => {
    return (<input className={'permission'} type='checkbox' checked={data?.[dataKey]} onChange={(e) => canModify && onChange(subtype, dataKey, e.target.checked)}/>)
}

const PermissionList = ({

}) => {
    const activeWinery = useSelector(selectActiveWinery)
    const users = useSelector(selectUsers);
    const user = useSelector(selectUser);
    const dispatch = useDispatch();
    const [getLabel] = useLabels();
    const navigate = useNavigate();
    const axiosPrivate = useAxiosPrivate();
    const [roles, setRoles] = useState([]);

    const updatePermissions = async (subtype, prop, value) => {
        const toastId = toast.loading(getLabel("toast_inProgress"), {
            type: toast.TYPE.INFO,
            position: toast.POSITION.BOTTOM_RIGHT,
            exclude: true,
          });
      
        const response = await updateSubtypePermissions(subtype, prop, value, axiosPrivate);
        
        toast.update(toastId, {
            render: response?.success
              ? getLabel(response?.success, { type: "" })
              : getLabel(response?.error),
            type: response?.error ? toast.TYPE.ERROR : toast.TYPE.SUCCESS,
            isLoading: false,
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 4000,
        });
        
          if (response && response?.success) {
            // dispatch(emptyUsers());
            loadUsers();
            // navClickHandler();
        }
    }

    const columns = useMemo(() => [
        {
            Header: getLabel('clientUsername'),
            accessor: (row) => row?.subtype && getLabel(`user_subtype_${row.subtype}`)
        },
        // {
        //     Header: getLabel('homeNavLink'),
        //     accessor: 'home'
        // },
        // {
        //     Header: getLabel('programmingNavLink'),
        //     accessor: 'programming'
        // },
        {
            Header: getLabel('lotNavLink'),
            accessor: (row) => <PermissionColumn canModify={user?.role === 2} data={row?.settings?.permissions || {}} dataKey={'lots'} subtype={row.subtype} onChange={updatePermissions} />
        }, {
            Header: getLabel('tanksNavLink'),
            accessor: (row) => <PermissionColumn canModify={user?.role === 2} data={row?.settings?.permissions || {}} dataKey={'tanks'} subtype={row.subtype} onChange={updatePermissions}/>
        }, {
            Header: getLabel('protocolsNavLink'),
            accessor: (row) => <PermissionColumn canModify={user?.role === 2} data={row?.settings?.permissions || {}} dataKey={'protocols'} subtype={row.subtype} onChange={updatePermissions}/>
        }, {
            Header: getLabel('wineriesNavLink'),
            accessor: (row) => <PermissionColumn canModify={user?.role === 2} data={row?.settings?.permissions || {}} dataKey={'wineries'} subtype={row.subtype} onChange={updatePermissions}/>
        },
    ]);

    const loadUsers = async () => {
        const currentUsers = await getUsers(axiosPrivate);
        // const currentUsers = await getUsers(axiosPrivate, activeWinery.id, user.userName);
        dispatch(setUsers(currentUsers));
    }

    useEffect(() => {
        const roles = new Set([]);
        users?.reduce((_, u) => u?.subtype && Number(u?.role_id) >= 3 && roles.add(u?.subtype), null);
        const rolesArray = Array.from(roles)?.map((r) => { return { subtype: r, settings: (users?.find((u) => u?.subtype === r)?.settings || {})}}) || [];
        setRoles(rolesArray);
    }, [users]);

    const renderList = () => {
        if (users.length > 1) {
            return (
                <div className="users_list_table">
                    {/* <TableEdit
                        defaultData={roles}
                        columns={columns}
                        isSearchBar={false}
                        rowClickHandler={(row) => {navigate(`/users/${row.original.id}`)}}
                        isEditable={false}
                    /> */}
                    <Table
                        isSearchBar={false}
                        data={roles}
                        prefix='permissions'
                        customColumns={columns}
                    />
                </div>
            )
        }
        return (<div className="users_alert">
            <h6 className="users_alert_title">{getLabel('noUsersAlert')}</h6>
        </div>)
    }

    return <div className="users_list">
        {renderList()}
    </div>


}

export default PermissionList