import { createSlice } from "@reduxjs/toolkit";
import { setDefaultOptions } from "date-fns";
import { enGB, it, enUS } from "date-fns/locale";

export const getLocaleObject = (locale) => {
    switch (locale) {
        case 'it':
        case 'it_IT':
            return it
        case 'en':
        case 'en_GB':
            return enGB
        case 'en_US':
        default:
            return enUS
    }
}

export const configSlice = createSlice({
    name: 'configs',
    initialState: {
        locale: 'it',
        area: 'public',
        baseUrl: window.origin,
        dateFormat: 'eee dd MMM yyyy HH:mm',
        hourShortDateFormat: 'dd/MM/yy HH:mm',
        hourMediumDateFormat: 'eee dd MMM HH:mm',
        mediumDateFormat: 'eee dd MMM yyyy',
        shortDateFormat: 'dd/MM/yy',
        compactDateFormat: 'eee dd MMM',
        utcDateFormat: "yyyy/MM/dd'T'HH:mm:ss.SSS'Z'",
        weekStartsOn: 0,
        firstWeekContainsDate: 1,
        tanksDisplayGrid: false,
        settings: {
            nextOperationMonthsInFutureDefault: 3,
        } 
    },
    reducers: {
        setLocale: (state, action) => {
            const { locale, weekStartsOn, firstWeekContainsDate } = action.payload
            setDefaultOptions({
                locale: getLocaleObject(locale),
                ...(weekStartsOn && { weekStartsOn: weekStartsOn }),
                ...(firstWeekContainsDate && { firstWeekContainsDate: firstWeekContainsDate })
            })
            return {
                ...state,
                locale: locale
            }
        },
        setArea: (state, action) => {
            const { area } = action.payload
            return {
                ...state,
                area: area
            }
        },
        setSettings: (state, action) => {
            return {
                ...state,
                settings: {
                    ...state.settings,
                    ...action.payload
                }
            }
        },
        toggleTanksDisplayGrid: (state, action) => {
            return {
                ...state,
                tanksDisplayGrid: !state.tanksDisplayGrid
            }
        },
        toggleLotsDisplayGrid: (state, action) => {
            return {
                ...state,
                lotsDisplayGrid: !state.lotsDisplayGrid
            }
        },
        toggleProtocolsDisplayGrid: (state, action) => {
            return {
                ...state,
                protocolsDisplayGrid: !state.protocolsDisplayGrid
            }
        },
        toggleUsersDisplayGrid: (state, action) => {
            return {
                ...state,
                usersDisplayGrid: !state.usersDisplayGrid
            }
        },
        toggleWineriesDisplayGrid: (state, action) => {
            return {
                ...state,
                wineriesDisplayGrid: !state.wineriesDisplayGrid
            }
        },
        toggleSubstancesDisplayGrid: (state, action) => {
            return {
                ...state,
                substancesDisplayGrid: !state.substancesDisplayGrid
            }
        },
        toggleClientsDisplayGrid: (state, action) => {
            return {
                ...state,
                clientsDisplayGrid: !state.clientsDisplayGrid
            }
        },
        toggleNotificationsUnreadGrid: (state, action) => {
            return {
                ...state,
                notificationsUnreadGrid: !state.notificationsUnreadGrid
            }
        }
    }
});



export const { setLocale, setArea, toggleTanksDisplayGrid, toggleLotsDisplayGrid, toggleProtocolsDisplayGrid, 
    toggleUsersDisplayGrid, toggleWineriesDisplayGrid, toggleSubstancesDisplayGrid, toggleClientsDisplayGrid, toggleNotificationsUnreadGrid, setSettings } = configSlice.actions

export default configSlice.reducer